import { Grid, Stack, Typography } from "@mui/material";
import DataGrid from "global/components/UI/DataGrid/DataGrid";
import { RowsDataDefinition } from "global/components/UI/DataGrid/types";
import ModalMessageDialog from "global/components/UI/MessageDialogs/ModalMessageDialog";
import { useModalDialog } from "global/hook/modal/use-modals";
import { useWindowViewport } from "global/hook/windowViewport/use-window-viewport";
import MESSAGES from "global/messages";
import { returnUndefinedIfOnlyWhitespace } from "global/util/utils";
import { useEffect, useState } from "react";
import IpWhitelistService from "service/logfile-service/Whitelist.service";
import IpWhitelist from "service/logfile-service/interface/IpWhitelist";
import ExportService from "service/report-service/export/Export.service";
import IpWhitelistAddDialogContent from "./component/IpWhitelistAddDialogContent";
import IpWhitelistGridActions from "./component/IpWhitelistGridActions";
import IpWhitelistColumnDefs from "./grid-column-defs";

const IpWhitelistPage = () => {
  const [dialogHelper, dialogController] = useModalDialog("ip-whitelist-add");
  const [selection, setSelection] = useState<IpWhitelist>();
  const [gridDataDef, setGridDataDef] = useState<
    RowsDataDefinition<IpWhitelist>
  >({ isFetchingData: false });

  const windowViewport = useWindowViewport(0, 268);

  const onBeginFetchingRecords = () => {
    setGridDataDef({
      ...gridDataDef,
      isFetchingData: true,
    });
  };

  const onEndFetchingRecords = (data?: Array<IpWhitelist>) => {
    setGridDataDef({
      data: data ? [...data] : gridDataDef.data,
      isFetchingData: false,
    });
  };

  const onEndFetchingRecordsWithError = (error: Error) => {
    onEndFetchingRecords();
  };

  const reloadRecords = () => {
    onBeginFetchingRecords();
    IpWhitelistService.load({
      callbackOnSuccess: (records: Array<IpWhitelist>) =>
        onEndFetchingRecords(records),
      callbackOnFail: onEndFetchingRecordsWithError,
    });
  };

  useEffect(() => {
    reloadRecords(); // eslint-disable-next-line
  }, []);

  var ipWhitelistEntry: IpWhitelist = {} as IpWhitelist;
  const ipWhitelistDetailsChangeHandler = (entry: IpWhitelist) => {
    ipWhitelistEntry = {
      ...ipWhitelistEntry,
      ...entry,
    };
    dialogController.disableOkAction(
      returnUndefinedIfOnlyWhitespace(ipWhitelistEntry.host) === undefined ||
        returnUndefinedIfOnlyWhitespace(ipWhitelistEntry.username) === undefined
    );
  };

  const saveIpToWhitelist = () => {
    IpWhitelistService.save(ipWhitelistEntry, (ipEntry: IpWhitelist) => {
      reloadRecords();
    });
  };

  const showAddIpDialog = () => {
    ipWhitelistEntry = {} as IpWhitelist;
    dialogController.showDialog({
      message: "",
      title: MESSAGES.DIALOG_TITLE_ADD_ENTRY,
      content: (
        <IpWhitelistAddDialogContent
          onDetailsChange={ipWhitelistDetailsChangeHandler}
        />
      ),
      okActionDisabled: true,
      onOkClick: saveIpToWhitelist,
      okCaption: MESSAGES.BUTTON_SAVE,
      onAbortClick: () => {},
      abortCaption: MESSAGES.BUTTON_CANCEL,
    });
  };

  const editIpWhitelistEntry = (entry: IpWhitelist) => {
    if (entry === selection) {
      ipWhitelistDetailsChangeHandler(selection);
      dialogController.showDialog({
        message: "",
        content: (
          <IpWhitelistAddDialogContent
            onDetailsChange={ipWhitelistDetailsChangeHandler}
            entry={selection}
          />
        ),
        onOkClick: saveIpToWhitelist,
        okCaption: MESSAGES.BUTTON_SAVE,
        onAbortClick: () => {},
        abortCaption: MESSAGES.BUTTON_CANCEL,
      });
    }
  };

  return (
    <Stack className="ipRange">
      <Grid container spacing={5} width={"auto"}>
        <Grid item xs={12} mt={3}>
          <Typography variant={"h1"}>IP Whitelist</Typography>
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            height={windowViewport.height}
            columnDefs={IpWhitelistColumnDefs}
            rowsDataDef={gridDataDef}
            gridActions={
              <IpWhitelistGridActions
                record={selection}
                reloadTrigger={reloadRecords}
                showAddIpWhitelistDialog={showAddIpDialog}
              />
            }
            onRowSelected={setSelection}
            onRowClicked={editIpWhitelistEntry}
            exportDef={{
              action: () => {
                ExportService.ipWhitelist();
              },
            }}
          />
        </Grid>
      </Grid>
      {dialogHelper && <ModalMessageDialog {...dialogHelper} />}
    </Stack>
  );
};

export default IpWhitelistPage;
