import { useCallback, useEffect, useRef, useState } from "react";
import { DataFetchCallbacks } from "../types";

interface UseGridDataFetchProps<T> {
  uncontrolledFetch?: (params: DataFetchCallbacks<T>) => void;
  onDataChange?: (data: T[]) => void;
  dataCacheFill: (data: T[]) => void;
}

export function useGridDataFetch<T>({
  uncontrolledFetch,
  onDataChange,
  dataCacheFill,
}: UseGridDataFetchProps<T>) {
  const [refreshing, setRefreshing] = useState(false);
  const mountedRef = useRef(true);
  const currentFetchId = useRef(0);

  // Setup cleanup on unmount
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const fetchData = useCallback(
    (hasCacheData: boolean, force = false) => {
      if (!uncontrolledFetch) return;
      if (hasCacheData && !force) return;

      const fetchId = ++currentFetchId.current;
      setRefreshing(true);

      uncontrolledFetch({
        callbackOnSuccess: (data: T[]) => {
          // Only update if component is mounted and this is the latest fetch
          if (mountedRef.current && fetchId === currentFetchId.current) {
            setRefreshing(false);
            dataCacheFill(data);
            onDataChange?.(data);
          }
        },
        callbackOnFail: (error: Error) => {
          // Only update if component is mounted and this is the latest fetch
          if (mountedRef.current && fetchId === currentFetchId.current) {
            setRefreshing(false);
          }
        },
      });
    }, // eslint-disable-next-line
    [uncontrolledFetch, dataCacheFill, onDataChange]
  );

  const updateData = useCallback(
    (data: T[]) => {
      if (mountedRef.current) {
        dataCacheFill(data);
        onDataChange?.(data);
      }
    },
    [dataCacheFill, onDataChange]
  );

  return {
    refreshing,
    fetchData,
    updateData,
  };
}
