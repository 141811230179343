import { noop } from "global/util/utils";
import { useEffect, useState } from "react";

export const CACHE_STATUS = {
  EMPTY: "EMPTY",
  ACTIVE: "ACTIVE",
  EXPIRED: "EXPIRED",
} as const;

export type DataCacheEntryStatus =
  (typeof CACHE_STATUS)[keyof typeof CACHE_STATUS];

export const CACHE_LIFESPAN = {
  SHORT: "SHORT",
  LONG: "LONG",
} as const;

export type DataCacheLifespan =
  (typeof CACHE_LIFESPAN)[keyof typeof CACHE_LIFESPAN];

export interface DataCacheEntry<T> {
  key: string;
  data: Array<T>;
  status: DataCacheEntryStatus;
  sourceDatalifespan: DataCacheLifespan;
  secondsLeftToExpire: number;
  lifespanInSeconds: number;
  expireTimer?: NodeJS.Timeout;
}

export interface CacheProps {
  cacheKey: string;
  expireAgeInSeconds: number;
  lifespan: DataCacheLifespan;
}

export type DataCacheEntries = Array<DataCacheEntry<any>>;
export type DataCacheFillFunction<T> = (data: Array<T>) => void;

export type DataCacheListener = {
  key: string;
  listener: React.Dispatch<React.SetStateAction<DataCacheEntries>>;
};

let globalDataCache: DataCacheEntries = [];
let listeners: Array<DataCacheListener> = [];

function updateCacheAge(key: string) {
  const index = globalDataCache.findIndex((e) => e.key === key);
  if (index === -1) return;
  const cacheEntry = globalDataCache[index];
  if (cacheEntry.secondsLeftToExpire === 0) {
    clearInterval(cacheEntry.expireTimer);
    updateCacheData({
      key: key,
      status: CACHE_STATUS.EXPIRED,
      secondsLeftToExpire: 0,
      lifespanInSeconds: 0,
    } as DataCacheEntry<any>);
  } else {
    updateCacheData({
      ...cacheEntry,
      secondsLeftToExpire:
        cacheEntry.secondsLeftToExpire > 0
          ? cacheEntry.secondsLeftToExpire - 1
          : 0,
    });
  }
}

function updateCacheData<T>(cacheEntry: DataCacheEntry<T>) {
  const index = globalDataCache.findIndex((e) => e.key === cacheEntry.key);
  if (index === -1) {
    return;
  }

  if (cacheEntry.data && cacheEntry.data.length === 0) {
    // remove cache entry
    globalDataCache = globalDataCache.filter((e) => e.key !== cacheEntry.key);
    // clear given timeout
    if (cacheEntry.expireTimer) {
      clearInterval(cacheEntry.expireTimer);
    }
    return;
  }

  const updatedDataCache = [...globalDataCache];
  updatedDataCache[index] = {
    ...globalDataCache[index],
    ...cacheEntry,
  };
  globalDataCache = updatedDataCache;

  for (const cacheListener of listeners) {
    cacheListener.listener(globalDataCache);
  }
}

export function useDataCache<T>(
  props: CacheProps
): [DataCacheEntry<T>, DataCacheFillFunction<T>] {
  const setState = useState(globalDataCache)[1];
  let cacheIndex = globalDataCache.findIndex((e) => e.key === props.cacheKey);

  if (props.cacheKey && cacheIndex === -1) {
    cacheIndex = globalDataCache.length;
    globalDataCache.push({
      key: props.cacheKey,
      data: [],
      sourceDatalifespan: props.lifespan,
      lifespanInSeconds: 0,
      status: CACHE_STATUS.EMPTY,
      secondsLeftToExpire: 0,
    });
  }

  const fillCache = (data: Array<T>) => {
    updateCacheData({
      key: props.cacheKey,
      data: data,
      sourceDatalifespan: props.lifespan,
      lifespanInSeconds: props.expireAgeInSeconds,
      status: CACHE_STATUS.ACTIVE,
      secondsLeftToExpire: props.expireAgeInSeconds,
      expireTimer: setInterval(() => {
        updateCacheAge(props.cacheKey);
      }, 1000),
    } as DataCacheEntry<T>);
  };

  useEffect(() => {
    if (props.cacheKey) {
      listeners.push({
        key: props.cacheKey,
        listener: setState,
      });
    }
    return () => {
      listeners = listeners.filter((li) => li.listener !== setState);
    };
  }, [props.cacheKey, setState]);

  if (cacheIndex === -1) return [{} as DataCacheEntry<T>, noop];
  return [globalDataCache[cacheIndex], fillCache];
}
