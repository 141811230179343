import { Dispatch, SetStateAction, useEffect, useState } from "react";

interface CacheDataRecord<T> {
  key: string;
  data: T;
}
type CacheDataStore = Array<CacheDataRecord<any>>;

interface CacheDataListener {
  key: string;
  listener: Dispatch<SetStateAction<CacheDataStore>>;
}
type CacheDataUpdateDispatch = (data: any) => void;

interface UseCachedDataOptions<T> {
  initialData?: T;
}

let globalCacheStore: CacheDataStore = [];
let listeners: Array<CacheDataListener> = [];

function updateCachedData<T>(cacheKey: string, data: T) {
  const index = globalCacheStore.findIndex((e) => e.key === cacheKey);
  const updatedDatastore = [...globalCacheStore];
  updatedDatastore[index] = {
    key: cacheKey,
    data: data,
  };
  globalCacheStore = updatedDatastore;
  for (const dataListener of listeners.filter((li) => li.key === cacheKey)) {
    dataListener.listener(globalCacheStore);
  }
}

export function useCachedData<T>(
  key: string,
  options: UseCachedDataOptions<T> = {}
): [T, CacheDataUpdateDispatch] {
  const setState = useState(globalCacheStore)[1];

  var recordIndex = globalCacheStore.findIndex((e) => e.key === key);
  if (recordIndex === -1) {
    globalCacheStore.push({
      key: key,
      data: options.initialData ?? {} as T
    });
    recordIndex = globalCacheStore.length - 1;
  }

  const updateDispatch: CacheDataUpdateDispatch = (data: T) => {
    updateCachedData(key, data);
  };

  useEffect(() => {
    listeners.push({
      key: key,
      listener: setState,
    });
    return () => {
      listeners = listeners.filter((li) => li.listener !== setState);
    };
  }, [key, setState]);

  return [globalCacheStore[recordIndex].data, updateDispatch];
}
