import { Box, Grid, IconButton, Stack } from "@mui/material";
import DataGrid from "global/components/UI/DataGrid/DataGrid";
import {
  UNPLAUSIBLE_RECHNUNGDATEN_STATUS_ENUM_ID,
  useEnumerator,
} from "global/hook/enumerator/use-enumerator";
import {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import ModalMessageDialog from "global/components/UI/MessageDialogs/ModalMessageDialog";
import MultipleSelectCheckbox from "global/components/UI/SelectBox/MultipleSelectCheckbox";
import useModalController, {
  useModalDialog,
} from "global/hook/modal/use-modals";
import { useWindowViewport } from "global/hook/windowViewport/use-window-viewport";
import UnplausibleRechnungenColumnDefs from "./grid-column-defs";

import { Tune } from "@mui/icons-material";
import { CACHE_LIFESPAN } from "global/components/UI/DataGrid/hooks/use-data-cache";
import {
  DataGridApi,
  DEFAULT_FILTER_CONFIG,
  FilterConfig,
} from "global/components/UI/DataGrid/types";
import { useCachedData } from "global/hook/cache/use-cached-data";
import { useTransponder } from "global/hook/transponder/use-transponder";
import MESSAGES from "global/messages";
import { HREF_AUTOMATENSUCHE } from "global/util/routes";
import { noop } from "global/util/utils";
import { useNavigate } from "react-router-dom";
import ServiceResponseHandler from "service/interface/ServiceResponseHandler";
import ExportService from "service/report-service/export/Export.service";
import { UnplausibleRechnungRecord } from "service/sap-service/interface/UnplausibleRechnungRecord";
import UnplausibleRechnungenGridActions from "./component/UnplausibleRechnungenDataGridActions";
import UnplausibleRechnungenFilterDialogContent, {
  FILTER_FIELD_DESCRIPTORS,
} from "./component/UnplausibleRechnungenFilter";
import UnplausibleRechnungenFilterSummary from "./component/UnplausibleRechnungenFilterSummary";
import UnplausibleRechnungenController from "./controller/UnplausibleRechnungenController";
import "./unplausiblerechnungen.scss";

export const UNPLAUSIBLE_RECHNUNGEN_FILTER_CACHE_KEY =
  "unplausible-rechnungen-data-filters";
export const UNPLAUSIBLE_RECHNUNGEN_STATUS_CACHE_KEY =
  "unplausible-rechnungen-status-filters";
export const FILTER_DEFAULT: Array<string> = ["IN_PROGRESS", "OPEN"];

const UnplausibleRechnungen: React.FC = () => {
  const statusEnumerator = useEnumerator(
    UNPLAUSIBLE_RECHNUNGDATEN_STATUS_ENUM_ID
  );

  const [dialogHelper, dialogController] = useModalDialog(
    "unplausible-rechnungen-add"
  );
  const modals = useModalController();

  const [filterConfig, setFilterConfig] = useCachedData<
    FilterConfig<UnplausibleRechnungRecord>
  >(UNPLAUSIBLE_RECHNUNGEN_FILTER_CACHE_KEY, {
    initialData: DEFAULT_FILTER_CONFIG,
  });

  const [statusFilter, setStatusFilter] = useCachedData<string>(
    UNPLAUSIBLE_RECHNUNGEN_STATUS_CACHE_KEY,
    {
      initialData: FILTER_DEFAULT.join(","),
    }
  );
  var inUseStatusFilter = useRef(FILTER_DEFAULT.join(","));

  useEffect(() => {
    inUseStatusFilter.current = statusFilter;
  }, [statusFilter]);

  // const [rechnungenStore, rechnungenDispatcher] = useUnplausibleRechnungen();
  const [statusOptions, setStatusOptions] = useState<Array<React.ReactNode>>(
    []
  );

  const dataGridApiRef = useRef<DataGridApi<UnplausibleRechnungRecord>>(null);

  const windowViewport = useWindowViewport(0, 242);
  const [gridHeight, setGridHeight] = useState(300);

  const transponderCtrl = useTransponder()[1];

  const navigate = useNavigate();

  const defaultColDef = useMemo(() => {
    return {
      initialWidth: 200,
      wrapHeaderText: true,
      autoHeaderHeight: true,
    };
  }, []);

  const updateFilterConfig = (
    config: FilterConfig<UnplausibleRechnungRecord>
  ) => {
    const testOp =
      Object.entries(config.filters).length > 0
        ? (record: UnplausibleRechnungRecord) =>
            UnplausibleRechnungenController.testGridRecord(record, config)
        : (record: UnplausibleRechnungRecord) => true;
    setFilterConfig({
      ...config,
      test: testOp,
    });
  };

  var wipFilterConfig: FilterConfig<UnplausibleRechnungRecord> =
    DEFAULT_FILTER_CONFIG;

  const handleRemoveAdvancedFilter = (fieldId: string) => {
    const newFilters = { ...filterConfig.filters };
    delete newFilters[fieldId];
    updateFilterConfig({ ...filterConfig, filters: newFilters });
  };

  const handleAdvancedFilterDialogShow = () => {
    wipFilterConfig = { ...DEFAULT_FILTER_CONFIG };
    dialogController.showDialog({
      message: "",
      title: "",
      content: (
        <UnplausibleRechnungenFilterDialogContent
          activeFilterConfig={filterConfig}
          onApplyFilters={(config) => {
            wipFilterConfig = { ...config };
          }}
          onClearFilters={() => {
            updateFilterConfig(DEFAULT_FILTER_CONFIG);
          }}
        />
      ),
      onOkClick: () => {
        updateFilterConfig(wipFilterConfig);
      },
      okCaption: MESSAGES.BUTTON_OK,
      onAbortClick: () => {},
      abortCaption: MESSAGES.BUTTON_CANCEL,
      dialogClassName: "dialog__UnplausibleRechnungenFilterDialog",
    });
  };

  useEffect(() => {
    setStatusOptions(
      statusEnumerator.items.map((item) => {
        return (
          <option value={item.id} key={item.id}>
            {item.value}
          </option>
        );
      })
    ); // eslint-disable-next-line
  }, [statusEnumerator]);

  const resetFilters = () => {
    handleStatusFilterChange(FILTER_DEFAULT);
    updateFilterConfig(DEFAULT_FILTER_CONFIG);

    fetchData();
  };

  const handleStatusFilterChange = (values: string[]) => {
    inUseStatusFilter.current = values.join(",");
    setStatusFilter(inUseStatusFilter.current);
  };

  function exportHandler() {
    ExportService.downloadUnplausibleRechnungen(statusFilter);
  }

  function onRowRecordClick(
    record: UnplausibleRechnungRecord,
    columnId: string
  ) {
    if (columnId === "latestNote") {
      onNoteClick(record);
    }
  }

  async function onNoteClick(record: UnplausibleRechnungRecord) {
    try {
      const automatInfo =
        await UnplausibleRechnungenController.handleNoteClickOn(record);

      transponderCtrl.setTransponderData(automatInfo);
      navigate(HREF_AUTOMATENSUCHE);
    } catch (error: Error | any) {
      dialogController.showDialog({
        message: error.message,
        title: MESSAGES.UNPLAUSIBLE_ERROR_PROCESSING, //"Error processing note click",
        onOkClick: noop,
      });
    }
  }

  const loadUnplausibleData = async (handler: ServiceResponseHandler) => {
    const data =
      await UnplausibleRechnungenController.loadUnplausibleRechnungData(
        inUseStatusFilter.current,
        modals
      );
    handler.callbackOnSuccess(data);
  };

  const fetchData = () => {
    if (inUseStatusFilter.current && dataGridApiRef.current) {
      dataGridApiRef.current.fetchData();
    }
  };

  const gridDataDecorator = useCallback(
    (
      record: UnplausibleRechnungRecord,
      dataGridApi: RefObject<DataGridApi<UnplausibleRechnungRecord>>
    ): UnplausibleRechnungRecord => {
      return {
        ...record,
        statusNode: UnplausibleRechnungenController.buildDropDownNode(
          record,
          inUseStatusFilter.current,
          dialogController,
          statusOptions,
          dataGridApi
        ),
      } as UnplausibleRechnungRecord;
    },
    [statusOptions]
  );

  useEffect(() => {
    setGridHeight(
      windowViewport.height -
        (Object.entries(filterConfig.filters).length > 0 ? 112 : 12)
    );
  }, [windowViewport, filterConfig.filters]);

  return (
    <Stack columnGap={2} className="mt_1rem ml_1rem">
      <Grid container spacing={2} width={"auto"}>
        <Grid item xs={12}>
          <Box className="uiElement rechnungen-status-multiselect">
            <MultipleSelectCheckbox
              limitTags={5}
              label="Status:"
              defaultValues={statusFilter.split(",")}
              id="status"
              options={statusEnumerator.items}
              onChange={(values) => {
                handleStatusFilterChange(values.map((v) => v.id as string));
              }}
              AutocompleteProps={{
                className: "inputTextFieldForKaufanfragenStatus",
              }}
              TypographyProps={{ className: "dialogTextStyle" }}
            />

            <IconButton
              color="secondary"
              size="small"
              onClick={handleAdvancedFilterDialogShow}
            >
              <Tune fontSize="small" />
            </IconButton>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <UnplausibleRechnungenFilterSummary
            filterConfig={filterConfig}
            onRemoveFilter={handleRemoveAdvancedFilter}
            filterFields={FILTER_FIELD_DESCRIPTORS}
          />
        </Grid>

        <Grid item xs={12}>
          <DataGrid
            dataGridRef={dataGridApiRef}
            height={gridHeight}
            columnDefs={UnplausibleRechnungenColumnDefs}
            gridActions={
              <UnplausibleRechnungenGridActions
                resetTrigger={resetFilters}
                reloadTrigger={fetchData}
                dialogController={dialogController}
              />
            }
            gridOptions={{
              defaultColDef: defaultColDef,
            }}
            //rowsDataDef={{ data: gridEventRecords }}
            rowsDataDef={{
              uncontrolledDataFetchCall: loadUnplausibleData,
              uncontrolledDataDecorator: gridDataDecorator,
              cacheProps: {
                cacheKey: "unplausible-rechnungen-records",
                expireAgeInSeconds: 120,
                lifespan: CACHE_LIFESPAN.LONG,
              },
              filterCacheKey: UNPLAUSIBLE_RECHNUNGEN_FILTER_CACHE_KEY,
            }}
            exportDef={{ action: exportHandler }}
            onRowClicked={onRowRecordClick}
          />
        </Grid>
      </Grid>
      {dialogHelper && <ModalMessageDialog {...dialogHelper} />}
    </Stack>
  );
};

export default UnplausibleRechnungen;
