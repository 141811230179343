import { Button, Grid, Stack, Typography } from "@mui/material";
import { EnumeratorItem } from "global/components/EnumeratedValues/Enumerator";
import DataGrid from "global/components/UI/DataGrid/DataGrid";
import DatePickerElement from "global/components/UI/DatePicker/DatePicker";
import ModalMessageDialog from "global/components/UI/MessageDialogs/ModalMessageDialog";
import SelectBox from "global/components/UI/SelectBox/SelectBox";
import StatusIconElement, {
  StatusIconType,
} from "global/components/UI/StatusIcon/StatusIcon";
import TextValue from "global/components/UI/TextValue/TextValue";
import {
  RECHNUNGDATEN_ATH_UNTERWARENGRUPPE_ENUM_ID,
  RECHNUNGDATEN_ATH_WARENGRUPPE_ENUM_ID,
  useEnumerator,
} from "global/hook/enumerator/use-enumerator";
import useModalController, {
  useModalDialog,
} from "global/hook/modal/use-modals";
import { useTransponder } from "global/hook/transponder/use-transponder";
import { useWindowViewport } from "global/hook/windowViewport/use-window-viewport";
import DateUtils, { getDateWithShift } from "global/util/DateUtils";
import { useEffect, useState } from "react";
import ExportService from "service/report-service/export/Export.service";
import AnlagenService from "service/sap-service/Anlagen.service";
import PositionService from "service/sap-service/Position.service";
import SapInputParam from "service/sap-service/interface/SapInputParam";
import SapOutputParam, {
  PositionDTO,
} from "service/sap-service/interface/SapOutputParam";
import AnlageListDialogContent from "./component/AnlageListDialogContent";
import SeriennrListDialogContent from "./component/SeriennrListDialogContent";
import SuchePositionenColumnDefs from "./grid-column-defs";

import { RowsDataDefinition } from "global/components/UI/DataGrid/types";
import MESSAGES from "global/messages";
import "./suchepositionen.scss";

const SuchePositionenCaption =
  "SerienNr rot = kein EPCOM Teilnehmer für diese Position(en)";
const emptyWorkData: RowsDataDefinition<PositionDTO> = {
  data: [],
  isFetchingData: false,
};

const RechnungsdatenSuchePositionen = () => {
  const modals = useModalController();
  const [dialog, dialogController] = useModalDialog("anlage-download");
  const [automatInfo, transponderCtrl] = useTransponder();

  const shift = -(90 + getDateWithShift(-90).getDate() - 1);
  const [lieferVonDate, setLieferVonDate] = useState<Date>(
    getDateWithShift(shift)
  );
  const [lieferBisDate, setLieferBisDate] = useState<Date>(getDateWithShift(0));
  const [searchData, setSearchData] =
    useState<RowsDataDefinition<PositionDTO>>(emptyWorkData);

  const [group, setGroup] = useState<EnumeratorItem | null>(null);
  const [subgroup, setSubroup] = useState<EnumeratorItem | null>(null);

  const [referenz, setReferenz] = useState<string>();
  const [rnsGln, setRnsGln] = useState<string>(automatInfo?.rnsGln ?? "");
  const [serriennr, setSerriennr] = useState<string>(
    automatInfo?.seriennr ?? ""
  );

  let groups = useEnumerator(RECHNUNGDATEN_ATH_WARENGRUPPE_ENUM_ID);
  let subgroups = useEnumerator(RECHNUNGDATEN_ATH_UNTERWARENGRUPPE_ENUM_ID);

  const windowViewport = useWindowViewport(0, 518);

  const groupChangeHandler = (
    selectedGroup: EnumeratorItem | undefined | null
  ) => {
    setGroup(selectedGroup ?? null);
  };
  const subgroupChangeHandler = (
    selectedGroup: EnumeratorItem | undefined | null
  ) => {
    setSubroup(selectedGroup ?? null);
  };
  const handleSearch = () => {
    getRecordsByUser();
  };

  const handleExport = () => {
    ExportService.downloadSapPositions(
      DateUtils.formatDateToAPIDateString(lieferVonDate)!,
      DateUtils.formatDateToAPIDateString(lieferBisDate)!,
      serriennr ?? "",
      referenz ?? "",
      group ? `${group.id}` : "",
      subgroup ? `${subgroup.id}` : "",
      rnsGln ?? ""
    );
  };

  const rowClickHandler = (entry: PositionDTO, columnId: string) => {
    if (columnId === "anlagen") {
      showAnlagenListDialog(entry);
    } else if (columnId === "serienNrEpcom") {
      showSeriennrListDialog(entry);
    }
  };

  const showAnlagenListDialog = (entry: PositionDTO) => {
    if (!entry.anlagen || entry.anlagen === "ANLAGEN_NOTFOUND") {
      return;
    }

    dialogController.showDialog({
      message: "",
      content: (
        <AnlageListDialogContent
          regulierungsBeleg={entry.regulierungsBeleg}
          anlageSelectHandler={anlageSelectHandler}
        />
      ),
      onAbortClick: () => {},
      abortCaption: MESSAGES.BUTTON_CANCEL,
    });
  };

  const showSeriennrListDialog = (entry: PositionDTO) => {
    if (entry.knzNotEpcom) {
      return;
    }
    dialogController.showDialog({
      message: "",
      title: MESSAGES.POSITIONEN_SERIALS_LIST_TITLE,
      content: (
        <SeriennrListDialogContent
          rnsGln={entry.rnsGln}
          seriennrSelectHandler={serriennrSelectHandler.bind(null, entry.id)}
        />
      ),
      onAbortClick: () => {},
      abortCaption: MESSAGES.BUTTON_CANCEL,
    });
  };

  const anlageSelectHandler = (regulierungsBeleg: string, anlage: string) => {
    dialogController.closeDialog();
    AnlagenService.downloadAnlagen(
      regulierungsBeleg,
      anlage === "Alle" ? "Anlagen.pdf" : anlage
    );
  };

  const serriennrSelectHandler = (id: number, seriennr: string) => {
    dialogController.closeDialog();
    PositionService.savePositionSerienNr(
      {
        id: id,
        serienNr: seriennr,
      } as SapInputParam,
      (output: SapOutputParam) => {
        if (output.errorTyp === MESSAGES.ERR_NO_ERROR) {
          getRecordsByUser();
        } else {
          modals.showMessageDialog(
            output.message,
            undefined,
            MESSAGES.DIALOG_TITLE_ERROR
          );
        }
      }
    );
  };

  const onBeginFetchingRecords = () => {
    setSearchData({
      ...searchData,
      isFetchingData: true,
    });
  };

  const onEndFetchingRecords = (output: SapOutputParam) => {
    if (output.errorTyp !== MESSAGES.ERR_NO_ERROR) {
      modals.showMessageDialog(
        output.message,
        undefined,
        MESSAGES.DIALOG_TITLE_ERROR
      );
    }
    setSearchData({
      data: [...(output.positionEdits ?? [])],
      isFetchingData: false,
    });
  };

  const callbackOnFail = () => {
    setSearchData(emptyWorkData);
  };

  const getRecordsByUser = () => {
    onBeginFetchingRecords();
    PositionService.getPositionenByUser(
      {
        lieferDatumStart: lieferVonDate,
        lieferDatumEnde: lieferBisDate,
        warengruppeNr: group ? group.id : undefined,
        unterWarengruppeId: subgroup ? subgroup.id : undefined,
        referenzNr: referenz,
        serienNr: serriennr,
        rnsGln: rnsGln,
      } as SapInputParam,
      onEndFetchingRecords,
      callbackOnFail
    );
  };

  useEffect(() => {
    if (automatInfo?.rnsGln || automatInfo?.seriennr) {
      getRecordsByUser();
    }
    transponderCtrl.clearData(); // eslint-disable-next-line
  }, []);

  return (
    <>
      <Grid container spacing={0} pt={4}>
        <Grid className="grid_item_30rem" item xs={12} sm={6}>
          <Stack spacing={2}>
            <DatePickerElement
              label="Lieferdatum von:"
              defaultValue={lieferVonDate}
              getSelectedValue={(value) => {
                setLieferVonDate(value ?? getDateWithShift(0));
              }}
              id="liefer-von-date"
              TypographyProps={{
                className: "lieferDateLabelStyle dialogTextStyle",
              }}
              TextFieldProps={{ className: "dialogTextStyle lieferDateInput" }}
              datePickerProperties={{
                maxDate: lieferBisDate,
              }}
            />
            <DatePickerElement
              label="Lieferdatum bis:"
              defaultValue={lieferBisDate}
              getSelectedValue={(value) => {
                setLieferBisDate(value ?? getDateWithShift(0));
              }}
              id="liefer-bis-date"
              TypographyProps={{
                className: "lieferDateLabelStyle dialogTextStyle",
              }}
              TextFieldProps={{ className: "dialogTextStyle lieferDateInput" }}
              datePickerProperties={{
                minDate: lieferVonDate,
              }}
            />
          </Stack>
        </Grid>
        <Grid className="grid_item_40rem" item xs={12} sm={6}>
          <Stack spacing={2}>
            <SelectBox
              id="warengruppe"
              label="Warengruppe:"
              enableClearable
              enumerator={groups}
              selection={group ?? undefined}
              getSelectedValue={(value) => groupChangeHandler(value)}
              TypographyProps={{
                className: "lieferDateLabelStyle dialogTextStyle",
              }}
            />
            <SelectBox
              id="unterwarengruppe"
              label="Unterwarengruppe:"
              enableClearable
              enumerator={subgroups}
              selection={subgroup ?? undefined}
              getSelectedValue={(value) => subgroupChangeHandler(value)}
              TypographyProps={{
                className: "lieferDateLabelStyle dialogTextStyle",
              }}
            />
          </Stack>
        </Grid>

        <Grid className="grid_item_30rem" item xs={12} sm={6}>
          <TextValue
            label="Referenz:"
            value={referenz}
            onChange={(e) => setReferenz(e.target.value)}
            TypographyProps={{
              className: "lieferDateLabelStyle dialogTextStyle",
            }}
            TextFieldProps={{
              className: "suchepositionen__referenz__TextStyle",
            }}
          />
        </Grid>
        <Grid className="grid_item_40rem" item xs={12} sm={6}>
          <Stack spacing={2}>
            <TextValue
              label="Serien-Nr.:"
              value={serriennr}
              onChange={(e) => setSerriennr(e.target.value)}
              TypographyProps={{
                className: "lieferDateLabelStyle dialogTextStyle",
              }}
            />
            <TextValue
              label="RNS-GLN:"
              value={rnsGln}
              onChange={(e) => setRnsGln(e.target.value)}
              TypographyProps={{
                className: "lieferDateLabelStyle dialogTextStyle",
              }}
            />
          </Stack>
        </Grid>

        <Grid item xs={12} mb={5}>
          <Stack direction={"row"} spacing={2}>
            <Button variant="contained" onClick={handleSearch}>
              {MESSAGES.POSITIONEN_SEARCH_BUTTON}
            </Button>
            <Button variant="contained" onClick={handleExport}>
              {MESSAGES.BUTTON_EXPORT}
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            height={windowViewport.height}
            columnDefs={SuchePositionenColumnDefs}
            rowsDataDef={searchData}
            onRowClicked={rowClickHandler}
            gridActions={<Typography variant={"h1"}>Positionen</Typography>}
          />
        </Grid>
        <Grid item xs={12}>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <StatusIconElement type={StatusIconType.RN} />
            <Typography variant={"h6"}>{SuchePositionenCaption}</Typography>
          </Stack>
        </Grid>
      </Grid>
      {dialog && <ModalMessageDialog {...dialog} />}
    </>
  );
};

export default RechnungsdatenSuchePositionen;
