import { useCallback, useEffect, useRef, useState } from "react";
import {
  CACHE_LIFESPAN,
  CACHE_STATUS,
  CacheProps,
  useDataCache,
} from "./use-data-cache";

interface UseGridCacheProps {
  cacheProps: CacheProps;
  isUncontrolledMode: boolean;
  refreshing: boolean;
}

export function useGridCache<T>({
  cacheProps,
  isUncontrolledMode,
  refreshing,
}: UseGridCacheProps) {
  const [cacheAgeProgress, setCacheAgeProgress] = useState<number>(0);
  const [canTriggerCacheRefresh, setCanTriggerCacheRefresh] =
    useState<boolean>(false);
  const mountedRef = useRef(true);

  const [cacheData, dataCacheFill] = useDataCache<T>(cacheProps);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  // Update cache progress
  useEffect(() => {
    if (!cacheProps.cacheKey || !mountedRef.current) return;

    const { lifespanInSeconds, secondsLeftToExpire } = cacheData;

    if (lifespanInSeconds === 0) {
      setCacheAgeProgress(0);
      return;
    }

    const progress =
      (100 * (lifespanInSeconds - secondsLeftToExpire)) / lifespanInSeconds;
    setCacheAgeProgress(progress);
  }, [cacheData]);

  // Update refresh state - separate effect to handle refreshing prop changes
  useEffect(() => {
    if (!mountedRef.current) return;

    const canRefresh = refreshing
      ? false
      : cacheData.sourceDatalifespan === CACHE_LIFESPAN.SHORT ||
        cacheData.status === CACHE_STATUS.EXPIRED;

    setCanTriggerCacheRefresh(canRefresh);
  }, [cacheData, refreshing]);

  // Safe cache update function
  const safeCacheFill = useCallback(
    (data: T[]) => {
      if (mountedRef.current) {
        dataCacheFill(data);
      }
    },
    [dataCacheFill]
  );

  const hasCachedData = (cacheData.data ?? []).length > 0;
  const showCacheDataActions =
    isUncontrolledMode &&
    cacheData.status &&
    cacheData.status !== CACHE_STATUS.EMPTY;

  return {
    cacheData,
    dataCacheFill: safeCacheFill,
    cacheAgeProgress,
    canTriggerCacheRefresh,
    hasCachedData,
    showCacheDataActions,
  };
}
