const MESSAGES = {
  DATAGRID_LOADING: "Wird geladen...",
  DATAGRID_NO_ROWS: "keine Daten vorhanden",
  FILL_ALL_REQUIERD: "Bitte alle Pflichtfelder füllen.",
  NO_AUTOMAT_FOUND_FOR_SERIAL: (serialNumber: string) =>
    `Kein Automat mit der Seriennummer ${serialNumber} gefunden`,

  DATA_LOAD_ERROR: "Beim Laden der Daten ist ein Fehler aufgetreten!",
  DATA_LOAD_NETWORK_ERROR:
    "Beim Abruf der Daten ist ein Netzwerkfehler aufgetreten!",
  DATA_FILE_LOAD_ERROR: "Beim Dateiabruf ist ein Fehler aufgetreten!",

  FIREWALL_SUPPORT_MESSAGE: (supportId: string) =>
    `Die Anfrage wurde von der Firewall abgelehnt. Bitte kopieren und melden Sie die unten aufgeführte Session ID an die EPG: \n\n${supportId}`,

  FIREWALL_ERROR_TITLE: "Firewall Fehler",
  // change tracking
  CHANGES_UNSAVED_ENTRIES: "Ungespeicherte Änderungen vorhanden!",
  CHANGES_UNSAVED_BEFORE_CLOSE_TITLE:
    "Der Automat, den Sie versuchen zu schließen, hat ungespeicherte Änderungen. Bitte speichern Sie diese, bevor Sie den Automaten schließen.",
  //Notizen
  NOTIZ_MISSING_TYPE: "Bitte einen Notiz Typ auswählen",
  NOTIZ_MISSING_STATUS: "Bitte einen Notiz Status auswählen",
  NOTIZ_MISSING_TEXT: "Bitte einen Text für die Notiz eingeben",
  NOTIZ_MISSING_VORGANG_NR:
    "Bitte eine Vorgangs-Nummer für die Unplausible Rechnung eingeben",
  // Unplausible rechnungen
  UNPLAUSIBLE_NO_AUTOMAT_GLN: "Kein Automat für diese RNS-GLN gefunden",
  UNPLAUSIBLE_MISSING_IDENTIFIER:
    "Entweder Seriennummer oder RNS-GLN muss angegeben werden",
  UNPLAUSIBLE_NO_AUTOMAT_KEY:
    "Automat konnte nicht eindeutig identifiziert werden",
  UNPLAUSIBLE_ERROR_PROCESSING: "Verarbeitung fehlgeschlagen",
  UNPLAUSIBLE_UNEXPECTED_PATH: "Unerwarteter Programmablauf",
  UNPLAUSIBLE_LOADING_RECORDS: "Unplausible Rechnungen werden geladen...",
  UNPLAUSIBLE_STATUS_CHANGE_TITLE: "",
  UNPLAUSIBLE_RECORD_CHANGE_COUNT: (count: number) =>
    `${count} Einträge wurden angepasst.`,
  // Menus
  MENU_COCKPIT: (role: string) => `Cockpit (${role})`,
  MENU_AUTOMATEN: "Automaten",
  MENU_AUTOMATEN_SUCHE: "Automatensuche",
  MENU_AUTOMATEN_KAUFANFRAGEN: "Kaufanfragen",
  MENU_BERICHTE: "Berichte",
  MENU_BERICHTE_FEHLERHAFTE_MM: "Ansicht fehlerhafte MM",
  MENU_BERICHTE_WECHSEL_NOK_OK: "Ansicht Wechsel NOK auf OK",
  MENU_BERICHTE_ERREICHBARKEITEN: "Erreichbarkeiten je KommArt.",
  MENU_BERICHTE_ZUR_BEARBEITUNG: "Zur Bearbeitung anstehend",
  MENU_BERICHTE_FLATFILE: "Flatfile",
  MENU_BERICHTE_DSMD_FEHLERHAFT: "DSMD fehlerhaft",
  MENU_BERICHTE_VERFUGBARKEIT: "Verfügbarkeitsanalyse",
  MENU_BERICHTE_AUTOMATEN_GT_30: "Automatendaten > 30",
  MENU_DATENPFLEGE: "Datenpflege",
  MENU_DATENPFLEGE_RNS: "RNS",
  MENU_NOTIZEN: "Notizen",
  MENU_NOTIZEN_SUCHE: "Suche",
  MENU_NOTIZEN_WIEDERVORLAGE: "Wiedervorlage",
  MENU_HILFE: "Hilfe",
  MENU_HILFE_HANDBUCH: "Benutzerhandbuch",
  MENU_ADMIN: "Administration",
  MENU_ADMIN_NACHRICHTEN: "Anwendernachrichten",
  MENU_ADMIN_NEUER_USER: "Anlage neuer User",
  MENU_ADMIN_RECHNUNGSNEHMER: "Anlage Rechnungsnehmer",
  MENU_ADMIN_STAMMDATEN: "Stammdatenabholung Clearer",
  MENU_ADMIN_IP_BEREICH: "IP Bereich",
  MENU_ADMIN_IP_WHITELIST: "IP Whitelist",
  MENU_ADMIN_JOB_MONITORING: "Job Monitoring",
  MENU_ADMIN_MAINTENANCE: "Maintenance",
  MENU_RECHNUNGSDATEN: "Rechnungsdaten ATH",
  MENU_RECHNUNGSDATEN_SUCHE: "Suche Positionen",
  MENU_RECHNUNGSDATEN_GLN: "GLN's ohne Zuordnung",
  MENU_RECHNUNGSDATEN_WGR: "WGR Zuordnung",
  MENU_RECHNUNGSDATEN_POSITIONEN: "Positionen",
  MENU_RECHNUNGSDATEN_REGIONEN: "Regionen zuordnen",
  MENU_RECHNUNGSDATEN_UNPLAUSIBEL: "Unplausible Rechnungspos.",
  //
  // Automatenblatt
  AUTOMATENBLATT_SAP_BONNUMMER_INFO:
    "Soll der Text zur neuen SAP Bonnummer übernommen werden?",
  //AbrufPanel
  ABRUF_OP_ERROR: "Aufgabe konnte nicht ausgeführt werden",
  ABRUF_PING_RUNNING:
    "Der Ping wird im Hintergrund ausgeführt, bitte haben Sie einen Moment Geduld.",
  ABRUF_TRACEROUTE_RUNNING:
    "Der Traceroute wird im Hintergrund ausgeführt, bitte haben Sie einen Moment Geduld.",
  // AnwenderNachrichten
  ANWENDER_SAVE_CONFIRMATION: "Nachricht wurde gespeichert.",
  ANWENDER_DELETE_CONFIRMATION: "Nachricht wurde gelöscht.",
  ANWENDER_MAX_LENGTH: (maxLength: number) =>
    `Die maximale Länge für Anwendernachrichten beträgt ${maxLength} Zeichen.`,
  // AnlageRechnungsnehmer
  ANLAGE_SAVE_CONFIRMATION: "Rechnungsnehmer wurde gespeichert.",
  // AnlageNeuerUser
  ANLAGE_USER_SAVE_CONFIRMATION: "Benutzer wurde gespeichert.",
  // GLN Change
  GLN_CHANGE_RNS_SEARCH_LABEL: "GLN der neuen RNS:",
  // GLN Ohne Zuordnung
  GLN_RNS_ZUORDNEN_TITLE: "RNS-GLN zuordnen",
  // DatenpflegeRns
  RNS_13_GLN_EXPECTED: "Bitte geben Sie eine 13-stellige GLN ein!",
  RNS_SAVE_CONFIRMATION: "RNS wurde gespeichert.",
  RNS_NO_RUCKNAHMESTELLE_FOR_GLN:
    "Es wurde keine Rücknahmestelle mit dieser GLN gefunden.",
  RNS_MULTIPLE_RUCKNAHMESTELLE_FOR_GLN:
    "Zu der GLN wurden mehrere Rücknahmestellen gefunden.",
  //WGR Zuordnung
  WGR_SAVE_DIALOG_TITLE: "Warengruppe zuordnen",
  // Job Monitoring
  JOB_DETAILS_TITLE: "Job Details",
  //KAUFANFRAGEN
  KAUFANFRAGEN_PLACE_TITLE: "Kaufanfrage stellen",
  KAUFANFRAGEN_CREATE_TITLE: "Automat anlegen",
  KAUFANFRAGEN_REJECT_TITLE: "Kaufanfrage ablehnen",
  //Positionen
  POSITIONEN_SEARCH_BUTTON: "Suche Positionen",
  POSITIONEN_SERIALS_LIST_TITLE: "Seriennummer zuordnen",
  // UNPLAUSIBLE RECHNUNGEN
  UNPLAUSIBLE_RECHNUNGEN_CREATE_EVENT_BUTTON: "Eintrag erstellen",
  UNPLAUSIBLE_RECHNUNGEN_ACTIVE_FILTERS: "Aktive Filter",
  UNPLAUSIBLE_RECHNUNGEN_ADD_FILTER_LINE: "Filterzeile hinzufügen",
  UNPLAUSIBLE_RECHNUNGEN_FILTER_LOGIC: "Filterlogik",
  // DIALOG
  DIALOG_TITLE_SAVE: "Speichern",
  DIALOG_TITLE_SAVE_NOT_OK: "Speichern nicht erfolgreich",
  DIALOG_TITLE_ADD_ENTRY: "Eintrag hinzufügen",
  DIALOG_TITLE_UNSAVED_CHANGES: "Ungespeicherte Änderungen vorhanden!",
  DIALOG_TITLE_ERROR: "Fehler",
  DIALOG_CONTENT_INTERNAL_ERROR: "Internal error",
  DIALOG_CONTENT_NO_CHANGES: "Sie haben keine Änderungen eingegeben.",
  DIALOG_CONTENT_SAVING_CHANGES: "Speichern von Änderungen",
  DIALOG_CONTENT_SAVE_CONFIRMATION: "Erfolgreich gespeichert",
  // Buttons
  BUTTON_JA: "JA",
  BUTTON_OK: "OK",
  BUTTON_NEIN: "Nein",
  BUTTON_SAVE: "Speichern",
  BUTTON_SAVE_CHANGES: "Änderungen speichern",
  BUTTON_AUTOMAT_SAVE: "Automat Speichern",
  BUTTON_DELETE: "Löschen",
  BUTTON_SEARCH: "Suchen",
  BUTTON_APPLY_FILTER: "Filter anwenden",
  BUTTON_RESET: "Felder zurücksetzen",
  BUTTON_CANCEL: "Abbrechen",
  BUTTON_BACK: "Zurück",
  BUTTON_DISCARD: "Verwerfen",
  BUTTON_REJECT: "Ablehnen",
  BUTTON_APPROVE: "Genehmigen",
  BUTTON_COPY: "Kopieren",
  BUTTON_EXPORT: "Export",
  BUTTON_EXCEL_EXPORT: "EXCEL Export",
  BUTTON_JSON_EXPORT: "JSON Export",
  BUTTON_RNS_SEARCH: "RNS suchen",
  BUTTON_RNS_SAVE: "RNS Speichern",
  BUTTON_OTHER_RNS_GLN: "GLN RNS Ändern",
  //ERRORS
  ERR_NO_ERROR: "NO_ERROR",
  ERR_NO_DATA: "NO_DATA",
  ERR_USER_NO_ERROR: "USERERROR_NO_ERROR",
};

export default MESSAGES;
