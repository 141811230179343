import { ColDef, GridOptions, GridReadyEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { CacheProps } from "global/components/UI/DataGrid/hooks/use-data-cache";
import { ReactNode, Ref, RefObject } from "react";
import ServiceResponseHandler from "service/interface/ServiceResponseHandler";

export type FilterLogic = "AND" | "OR";

export interface FilterConfig<T> {
  filters: Record<string, string>;
  logic: FilterLogic;
  test: (record: T) => boolean;
}

export const DEFAULT_FILTER_CONFIG: FilterConfig<any> = {
  filters: {},
  logic: "AND",
  test: () => true,
};

export interface DataFetchCallbacks<T> {
  callbackOnSuccess: (data: T[]) => void;
  callbackOnFail: (error: Error) => void;
}

export interface RowsDataDefinition<T> {
  data?: T[];
  isFetchingData?: boolean;
  uncontrolledDataFetchCall?: (handler: ServiceResponseHandler) => void;
  uncontrolledDataDecorator?: (
    record: T,
    dataGridApi: RefObject<DataGridApi<T>>
  ) => T;
  cacheProps?: CacheProps;
  filterCacheKey?: string;
}

export interface ExportDefinition {
  name?: string;
  action: () => void;
  disabled?: boolean;
}

export interface DataGridApi<T> {
  cachedData: T[];
  gridData: T[];
  fetchData: Function;
  updateData: Function;
  scrollToIndex: Function;
}

export interface DataGridProps<T> {
  columnDefs: Array<ColDef>;
  rowsDataDef: RowsDataDefinition<T>;
  height?: number;
  className?: string;
  gridOptions?: GridOptions;
  exportDef?: ExportDefinition;
  gridActions?: ReactNode;
  onRowClicked?: (data: T, columnId: string) => void;
  onRowSelected?: (data: T | undefined) => void;
  onRowDataChanged?: (data?: T[]) => void;
  onGridReady?: (params: GridReadyEvent) => void;
  gridRef?: Ref<AgGridReact> | null;
  dataGridRef?: React.MutableRefObject<DataGridApi<T> | null>;
  rowHeight?: number;
}
