import { Box } from "@mui/material";
import { DataGridApi, FilterConfig } from "global/components/UI/DataGrid/types";
import ModalController, {
  DialogController,
} from "global/hook/modal/interface/ModalController";
import { AutomatTransponderInfo } from "global/hook/transponder/interface/Transponder";
import { AutomatViewHintsController } from "global/hook/viewHints/use-view-hints";
import MESSAGES from "global/messages";
import { findMachineKeyViaSerialNumberAsync } from "global/util/REST/findMachineKey";
import { noop } from "global/util/utils";
import { UNPLAUSIBLE_RECHNUNG_NOTE_TYPE } from "page/Automatensuche/components/Automatenblatt/components/panel/Notizen/NotizenPanel";
import { RefObject } from "react";
import DepositMachineDataService from "service/data-service/automate-controller/DepositMachineData.service";
import { UnplausibleRechnungRecord } from "service/sap-service/interface/UnplausibleRechnungRecord";
import UnplausibleRechnungenService from "service/sap-service/UnplausibleRechnungen.service";
import { FILTER_FIELD_DESCRIPTORS } from "../component/UnplausibleRechnungenFilter";

export interface RechnungItem {
  id: string;
  description: string;
  selected: boolean;
}

const unplausibleRechnungRecordCompare = (
  a: UnplausibleRechnungRecord,
  b: UnplausibleRechnungRecord
) => {
  const glnTest = -1 * a.rnsGln.localeCompare(b.rnsGln);
  return glnTest === 0 ? a.eventId - b.eventId : glnTest;
};

const UnplausibleRechnungenController = {
  async handleNoteClickOn(
    record: UnplausibleRechnungRecord
  ): Promise<AutomatTransponderInfo> {
    const automatInfo =
      await UnplausibleRechnungenController.getAutomatTransponderInfoForRecord(
        record
      );

    if (!automatInfo.automatKey) {
      throw new Error(MESSAGES.UNPLAUSIBLE_NO_AUTOMAT_KEY); //"No automat key found"
    }

    AutomatViewHintsController.registerAutomatViewHints({
      automatKey: automatInfo.automatKey,
      infoPanelIndex: 7,
      notePanelData: record.noteKey
        ? { noteKey: record.noteKey }
        : {
            type: UNPLAUSIBLE_RECHNUNG_NOTE_TYPE,
            rechnungItem: {
              vorgang_nr: `${record.eventId}`,
              regulierungs_beleg: record.regulierungsBeleg,
            },
          },
    });
    return automatInfo;
  },
  async getAutomatTransponderInfoForMarket(
    gln: string,
    preferedSerialNumber?: string
  ): Promise<AutomatTransponderInfo | undefined> {
    const data = await DepositMachineDataService.retrieveMarketMachineData(gln);
    if (data.length === 0) return undefined;

    if (preferedSerialNumber) {
      const preferedAutomat = data.find(
        (item) => item.automatSerienNr === preferedSerialNumber
      );
      if (preferedAutomat) {
        return {
          seriennr: preferedAutomat.automatSerienNr,
          automatKey: preferedAutomat.automatkey,
        } as AutomatTransponderInfo;
      }
    }

    const automat = data.reduce((minRecord, item) =>
      Number(item.automatSerienNr) < Number(minRecord.automatSerienNr)
        ? item
        : minRecord
    );

    return {
      seriennr: automat.automatSerienNr,
      automatKey: automat.automatkey,
    } as AutomatTransponderInfo;
  },
  async getAutomatTransponderInfoForRecord(
    record: UnplausibleRechnungRecord
  ): Promise<AutomatTransponderInfo> {
    const automatSerialNumber =
      record.noteAutomatSerialNumber ?? record.seriennr;

    if (!automatSerialNumber && !record.rnsGln) {
      throw new Error(MESSAGES.UNPLAUSIBLE_MISSING_IDENTIFIER); //"Either seriennr or rnsGln must be provided"
    }

    if (record.rnsGln) {
      // we have a market gln. attempt to find the automat via market gln
      // if we also have a serial number, we will try to return the automat with the serial number
      const info =
        await UnplausibleRechnungenController.getAutomatTransponderInfoForMarket(
          record.rnsGln,
          automatSerialNumber
        );
      if (info) return info;
    }
    if (!automatSerialNumber) {
      // we could not find the automat via market gln. we have no serial number to attempt to find the automat
      throw new Error(MESSAGES.UNPLAUSIBLE_NO_AUTOMAT_GLN); //"No automat for this RNS-GLN found"
    }
    // we have a serial number but no market gln.  or there is a market gln but no automat found within the market
    // attemt to find the automat via serial number
    // findMachineKeyViaSerialNumberAsync will throw an error if no automat is found
    const key = await findMachineKeyViaSerialNumberAsync(automatSerialNumber);
    return {
      seriennr: automatSerialNumber,
      automatKey: key,
    } as AutomatTransponderInfo;
  },
  async loadUnplausibleRechnungData(
    statusFilter: string,
    modals: ModalController
  ): Promise<UnplausibleRechnungRecord[]> {
    if (statusFilter === "") {
      return [];
    }
    modals.showLoadingDialog(MESSAGES.UNPLAUSIBLE_LOADING_RECORDS); //"Unplausible Rechnungen werden geladen..."
    const data = await UnplausibleRechnungenService.suche(statusFilter);
    modals.closeModal();
    return data.sort((a, b) => unplausibleRechnungRecordCompare(a, b));
  },
  buildDropDownNode(
    eventRecord: UnplausibleRechnungRecord,
    statusFilter: string,
    dialogController: DialogController,
    statusOptions: Array<React.ReactNode>,
    dataGridApi: RefObject<DataGridApi<UnplausibleRechnungRecord>>
  ): React.ReactNode {
    const onSelectionChange = async (
      event: React.ChangeEvent<HTMLSelectElement>
    ) => {
      const data = (await UnplausibleRechnungenService.updateStatus(
        eventRecord,
        event.target.value
      )) as Array<UnplausibleRechnungRecord>;

      UnplausibleRechnungenController.onUpdatedRecords(
        data,
        statusFilter,
        dataGridApi
      );

      dialogController.showDialog({
        message: MESSAGES.UNPLAUSIBLE_RECORD_CHANGE_COUNT(data.length),
        title: MESSAGES.UNPLAUSIBLE_STATUS_CHANGE_TITLE,
        onOkClick: noop,
      });
    };

    return (
      <Box className="box-with-centered-content">
        <select
          className="unplausibleRechnungen__dataGrid__statusOptions"
          name="statusOptions"
          value={eventRecord.status}
          onChange={onSelectionChange}
        >
          {statusOptions}
        </select>
      </Box>
    );
  },
  onUpdatedRecords(
    records: UnplausibleRechnungRecord[],
    activeStatusFilter: string,
    dataApi: RefObject<DataGridApi<UnplausibleRechnungRecord>>
  ) {
    if (records.length === 0) return;
    const dataGridApi = dataApi.current!;
    const updatedRecords = records.filter((record) =>
      activeStatusFilter.includes(record.status)
    );
    const removedIds = records
      .filter((record) => !activeStatusFilter.includes(record.status))
      .map((record) => record.id);

    const storeRecords = [...dataGridApi.cachedData].filter(
      (record) => !removedIds.includes(record.id)
    );

    updatedRecords.forEach((record) => {
      const index = storeRecords.findIndex((r) => r.id === record.id);
      if (index !== -1)
        storeRecords[index] = {
          ...storeRecords[index],
          status: record.status, // currently, only status is updated and reflected back in data grid
        };
    });
    dataGridApi.updateData(storeRecords);
    if (updatedRecords.length > 0) {
      const index = dataGridApi.gridData.findIndex(
        (item) => item.eventId === updatedRecords[0].eventId
      );
      dataGridApi.scrollToIndex(index);
    }
  },
  testGridRecord(
    record: UnplausibleRechnungRecord,
    predicate: FilterConfig<UnplausibleRechnungRecord>
  ): boolean {
    const filterResults = Object.entries(predicate.filters).map(
      ([field, value]) => {
        const recordValue = record[field as keyof UnplausibleRechnungRecord];

        // Skip if record value is null/undefined
        if (recordValue == null) {
          return false;
        }

        // Find field definition to determine type
        const fieldDef = FILTER_FIELD_DESCRIPTORS.find((f) => f.id === field);

        switch (fieldDef?.type) {
          case "number":
            return Number(recordValue) === Number(value);

          case "select":
            return String(recordValue) === value;

          default: // text
            return String(recordValue).toLowerCase() === value.toLowerCase();
        }
      }
    );

    // Apply logic based on filterConfig.logic
    return predicate.logic === "AND"
      ? filterResults.every(Boolean) // All conditions must be true
      : filterResults.some(Boolean); // At least one condition must be true
  },
};
export default UnplausibleRechnungenController;
