import { Cached, HistoryToggleOff } from "@mui/icons-material";
import { Box, Chip, CircularProgress, Fab, Stack } from "@mui/material";
import MESSAGES from "global/messages";
import { ReactNode } from "react";
import { ExportDefinition } from "../types";

interface GridActionsProps {
  showActions: boolean;
  showCacheActions: boolean;
  exportDef?: ExportDefinition;
  gridActions?: ReactNode;
  refreshing: boolean;
  canTriggerRefresh: boolean;
  cacheAgeProgress: number;
  onRefresh: () => void;
}

export function GridActions({
  showActions,
  showCacheActions,
  exportDef,
  gridActions,
  refreshing,
  canTriggerRefresh,
  cacheAgeProgress,
  onRefresh,
}: GridActionsProps) {
  if (!showActions && !showCacheActions) return null;

  return (
    <Stack
      className="gridActionsRow buttonStack"
      justifyContent="space-between"
    >
      <Stack className="gridActionsRow filterButtons">{gridActions}</Stack>

      {(exportDef || showCacheActions) && (
        <Stack className="gridActionsRow">
          {exportDef && (
            <Chip
              className="exportChip"
              size="small"
              variant="outlined"
              label={exportDef.name ?? MESSAGES.BUTTON_EXCEL_EXPORT}
              clickable
              onClick={() => exportDef.action()}
              disabled={exportDef.disabled}
            />
          )}
          {showCacheActions && (
            <Box className="dataGrid__cacheDataActionContainer">
              <Fab
                color="primary"
                onClick={onRefresh}
                size="small"
                disabled={refreshing || !canTriggerRefresh}
              >
                {refreshing || !canTriggerRefresh ? (
                  <HistoryToggleOff />
                ) : (
                  <Cached />
                )}
              </Fab>
              <CircularProgress
                color="warning"
                className="dataGrid___cacheAgeProgress"
                variant={refreshing ? "indeterminate" : "determinate"}
                value={cacheAgeProgress}
              />
            </Box>
          )}
        </Stack>
      )}
    </Stack>
  );
}
