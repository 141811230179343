import { FilterList } from "@mui/icons-material";
import { Grid, Stack, Typography } from "@mui/material";
import Enumerator, {
  EnumeratorItem,
  enumeratorItemForValue,
} from "global/components/EnumeratedValues/Enumerator";
import DataGrid from "global/components/UI/DataGrid/DataGrid";
import SelectBox from "global/components/UI/SelectBox/SelectBox";
import TextValue from "global/components/UI/TextValue/TextValue";
import {
  RECHNUNGDATEN_ATH_UNTERWARENGRUPPE_ENUM_ID,
  useEnumerator,
} from "global/hook/enumerator/use-enumerator";
import { useEffect, useState } from "react";
import PositionService from "service/sap-service/Position.service";
import SapInputParam from "service/sap-service/interface/SapInputParam";
import SapOutputParam, {
  PositionDTO,
} from "service/sap-service/interface/SapOutputParam";
import WGRKurztextFilter from "./WGRKurztextFilter";
import WarengruppeZuordnungColumnDefs from "./grid-column-defs";

import { RowsDataDefinition } from "global/components/UI/DataGrid/types";
import { noop } from "global/util/utils";
import WarengruppenService from "service/sap-service/Warengruppen.service";
import UnterWarengruppe from "service/sap-service/interface/UnterWarengruppe";
import { WGR_OHNE_WGR } from "../WGRZuordnungUtil";
import "../wgrzuordnung.scss";

export interface WGREntryDetailsDialogContentProps {
  activeArea: EnumeratorItem;
  entry: PositionDTO;
  dataChangeListener: (value: SapInputParam) => void;
}

const UNASSIGNED_GROUP_ID = 1;

const WGREntryDetailsDialogContent: React.FC<
  WGREntryDetailsDialogContentProps
> = (props: WGREntryDetailsDialogContentProps) => {
  let groups = useEnumerator(RECHNUNGDATEN_ATH_UNTERWARENGRUPPE_ENUM_ID);

  const [allowedGroups, setAllowedGroups] = useState<Array<number>>([]);
  const [displayGroups, setDisplayGroups] = useState<Enumerator>({
    id: RECHNUNGDATEN_ATH_UNTERWARENGRUPPE_ENUM_ID,
    syncFN: noop,
    status: "INITIAL",
    items: [],
  });
  const [group, setGroup] = useState<EnumeratorItem | null>(null);

  const emptyWorkAreaData: RowsDataDefinition<PositionDTO> = {
    data: [],
    isFetchingData: false,
  };
  const [searchAreaData, setSearchAreaData] =
    useState<RowsDataDefinition<PositionDTO>>(emptyWorkAreaData);

  const groupChangeHandler = (
    selectedGroup: EnumeratorItem | undefined | null
  ) => {
    setGroup(selectedGroup ?? null);
    if (selectedGroup)
      props.dataChangeListener({
        kurzText: props.entry.kurzText,
        unterWarengruppeId: selectedGroup.id,
      } as SapInputParam);
  };

  const getRecordsByKurtzText = (filter?: string) => {
    if (!filter) {
      setSearchAreaData(emptyWorkAreaData);
      return;
    }
    setSearchAreaData({
      data: searchAreaData.data,
      isFetchingData: true,
    });
    PositionService.getPositionByKurzText(
      {
        kurzText: filter,
      } as SapInputParam,
      (output: SapOutputParam) => {
        const positions = (output.positionEdits ?? []).filter(
          (p) => allowedGroups.indexOf(p.unterWarengruppeId) !== -1
        );
        setSearchAreaData({
          data: [...positions],
          isFetchingData: false,
        });
      },
      () => {
        setSearchAreaData(emptyWorkAreaData);
      }
    );
  };

  const rowClickHandler = (entry: PositionDTO) => {
    groupChangeHandler(
      enumeratorItemForValue(groups, entry.unterWarengruppeName)
    );
  };

  useEffect(() => {
    if (props.activeArea === WGR_OHNE_WGR) {
      setAllowedGroups(groups.items.map((e: EnumeratorItem) => e.id as number));
    } else {
      WarengruppenService.getUnterwarengruppenByUwgrOfKurzText(
        props.entry,
        (data: any) => {
          setAllowedGroups(data.map((e: UnterWarengruppe) => e.id));
        }
      );
    }
  }, [props.activeArea, props.entry, groups]);

  useEffect(() => {
    setDisplayGroups({
      ...groups,
      items: groups.items.filter(
        (e) => allowedGroups.indexOf(e.id as number) !== -1
      ),
    });
  }, [allowedGroups, groups]);

  return (
    <Grid container spacing={1} width={900}>
      <Grid item xs={12}>
        <Stack className="wgrzuordnung__rowStack" columnGap={5}>
          <TextValue
            label="Kurztext:"
            value={props.entry.kurzText}
            id="kurz-text"
            readOnly
            TypographyProps={{
              className: "wgr__dialogLabelStyle dialogTextStyle",
            }}
            InputProps={{
              className: "dialogTextStyle wgr__longTextStyle",
            }}
          />
          <TextValue
            label="ATH:"
            value={props.entry.rechnungsstellerKuerzel}
            id="ath"
            readOnly
            TypographyProps={{
              className:
                "wgr__dialogLabelStyle dialogTextStyle wgr__dialogSmallLabelStyle",
            }}
            InputProps={{ className: " dialogTextStyle" }}
          />
        </Stack>
      </Grid>
      <Grid item xs={12}></Grid>
      <Grid item xs={12}>
        <Stack className="wgrzuordnung__rowStack" columnGap={5}>
          <SelectBox
            id="unterwarengruppe"
            label="Unterwarengruppe:"
            enableClearable
            enumerator={displayGroups}
            selection={group ?? undefined}
            getSelectedValue={(value) => groupChangeHandler(value)}
            TypographyProps={{
              className: "wgr__dialogLabelStyle dialogTextStyle",
            }}
          />
          {UNASSIGNED_GROUP_ID !== props.entry.unterWarengruppeId && (
            <Typography>{`Aktuell Unterwarengruppe - ${
              props.entry.unterWarengruppeName ?? ""
            }`}</Typography>
          )}
        </Stack>
      </Grid>
      <Grid item xs={12} mt={5}>
        <DataGrid
          height={200}
          columnDefs={WarengruppeZuordnungColumnDefs}
          rowsDataDef={searchAreaData}
          gridActions={
            <WGRKurztextFilter
              label="Kurztext filtern:"
              applyFilterWhileTyping
              applyFilter={getRecordsByKurtzText}
              icon={<FilterList />}
              TypographyProps={{
                className: "wgr__dialogLabelStyle dialogTextStyle",
              }}
              inputClassName="wgrFilter__dialogInputStyle"
            />
          }
          onRowClicked={rowClickHandler}
        />
      </Grid>
    </Grid>
  );
};

export default WGREntryDetailsDialogContent;
