import { Grid, Typography } from "@mui/material";
import { EnumeratorItem } from "global/components/EnumeratedValues/Enumerator";
import DataGrid from "global/components/UI/DataGrid/DataGrid";
import ModalMessageDialog from "global/components/UI/MessageDialogs/ModalMessageDialog";
import SelectBox from "global/components/UI/SelectBox/SelectBox";
import useModalController, {
  useModalDialog,
} from "global/hook/modal/use-modals";
import { useWindowViewport } from "global/hook/windowViewport/use-window-viewport";
import { useState } from "react";
import ErrorTypeDTO from "service/data-service/error-typ-controller/interface/ErrorType";
import PositionService from "service/sap-service/Position.service";
import WarengruppenService from "service/sap-service/Warengruppen.service";
import SapInputParam from "service/sap-service/interface/SapInputParam";
import SapOutputParam, {
  PositionDTO,
} from "service/sap-service/interface/SapOutputParam";
import WGREntryDetailsDialogContent from "./component/WGREntryDetailsDialogContent";
import WGRKurztextFilter from "./component/WGRKurztextFilter";
import WGRZuordnungColumnDefs, {
  WGRZuordnungOhneWGRColumnDefs,
} from "./grid-column-defs";

import ExportService from "service/report-service/export/Export.service";

import { RowsDataDefinition } from "global/components/UI/DataGrid/types";
import MESSAGES from "global/messages";
import WGRZuordnungWorkAreaEnumerator, {
  WGRZuordnungMitWGRCaption,
  WGRZuordnungOhneWGRCaption,
  WGR_MIT_WGR,
  WGR_OHNE_WGR,
  WGR_SEARCH,
} from "./WGRZuordnungUtil";
import "./wgrzuordnung.scss";

const WGRZuordnung = () => {
  const modals = useModalController();
  const [dialog, dialogController] = useModalDialog("wgr-zuordnung");

  const emptyWorkAreaData: RowsDataDefinition<PositionDTO> = {
    data: [],
    isFetchingData: false,
  };
  const [withoutWGRArea, setWithoutWGRArea] = useState<
    RowsDataDefinition<PositionDTO>
  >({ isFetchingData: false });
  const [withWGRArea, setWithWGRArea] = useState<
    RowsDataDefinition<PositionDTO>
  >({ isFetchingData: false });
  const [searchAreaData, setSearchAreaData] =
    useState<RowsDataDefinition<PositionDTO>>(emptyWorkAreaData);

  const [aktivArea, setAktivArea] = useState<EnumeratorItem>(WGR_SEARCH);
  const [searchTextGap, setSearchTextGap] = useState(80);
  const [kurtzTextFilter, setKurtzTextFilter] = useState<string>();

  const windowViewport = useWindowViewport(0, 260);

  let subgroupUpdateFilter: SapInputParam | undefined;
  let entryUnterWarrenGroup: number | undefined;
  let recordSetter = setSearchAreaData;

  const onFetchingAreaData = (
    existingRecords: Array<PositionDTO> | undefined
  ) => {
    recordSetter({
      data: existingRecords,
      isFetchingData: true,
    });
  };

  const activeAreaChangeHandler = (value: EnumeratorItem) => {
    setAktivArea(value);
    setSearchTextGap(value === WGR_SEARCH ? 80 : 0);
    reloadRecords(value);
  };

  const callbackOnSuccess = (output: SapOutputParam) => {
    recordSetter({
      data: [...(output.positionEdits ?? [])],
      isFetchingData: false,
    });
  };

  const callbackOnFail = () => {
    recordSetter(emptyWorkAreaData);
  };

  const getRecordsWithWGR = () => {
    recordSetter = setWithWGRArea;
    onFetchingAreaData(withWGRArea.data);
    PositionService.getPositionWithWgrForEdit(
      callbackOnSuccess,
      callbackOnFail
    );
  };

  const getRecordsWithoutWGR = () => {
    recordSetter = setWithoutWGRArea;
    onFetchingAreaData(withoutWGRArea.data);
    PositionService.getPositionWithoutWgrForEdit(
      callbackOnSuccess,
      callbackOnFail
    );
  };

  const getRecordsByKurtzText = (filter?: string) => {
    if (!filter) {
      setSearchAreaData(emptyWorkAreaData);
      return;
    }
    recordSetter = setSearchAreaData;
    onFetchingAreaData(searchAreaData.data);
    setKurtzTextFilter(filter);
    PositionService.getPositionByKurzText(
      {
        kurzText: filter,
      } as SapInputParam,
      callbackOnSuccess,
      callbackOnFail
    );
  };

  const reloadRecords = (recordsArea: EnumeratorItem, filter?: string) => {
    switch (recordsArea) {
      case WGR_SEARCH:
        getRecordsByKurtzText(filter);
        break;
      case WGR_MIT_WGR:
        getRecordsWithWGR();
        break;
      case WGR_OHNE_WGR:
        getRecordsWithoutWGR();
        break;
      default:
        break;
    }
  };

  const doWGREntryUpdate = () => {
    if (entryUnterWarrenGroup && subgroupUpdateFilter) {
      modals.showLoadingDialog(MESSAGES.DIALOG_CONTENT_SAVING_CHANGES);
      WarengruppenService.saveMatching(
        entryUnterWarrenGroup,
        subgroupUpdateFilter,
        (err: ErrorTypeDTO) => {
          modals.closeModal();
          if (err.key !== MESSAGES.ERR_NO_ERROR) {
            modals.showMessageDialog(
              err.value,
              undefined,
              MESSAGES.DIALOG_TITLE_ERROR
            );
          } else {
            reloadRecords(aktivArea, kurtzTextFilter);
          }
        },
        () => {
          modals.closeModal();
        }
      );
    }
  };

  const wgrUnterWarrengruppeChangeHandler = (data: SapInputParam) => {
    subgroupUpdateFilter = data;
  };

  const rowClickHandler = (entry: PositionDTO) => {
    subgroupUpdateFilter = undefined;
    entryUnterWarrenGroup = entry.unterWarengruppeId;
    dialogController.showDialog({
      message: "",
      title: MESSAGES.WGR_SAVE_DIALOG_TITLE,
      content: (
        <WGREntryDetailsDialogContent
          activeArea={aktivArea}
          entry={entry}
          dataChangeListener={wgrUnterWarrengruppeChangeHandler}
        />
      ),
      onOkClick: doWGREntryUpdate,
      okCaption: MESSAGES.BUTTON_SAVE,
      onAbortClick: () => {},
      abortCaption: MESSAGES.BUTTON_CANCEL,
      maxWidth: "lg",
    });
  };

  return (
    <>
      <Grid container spacing={5} width={"auto"}>
        <Grid item xs={12} mt={4}>
          <SelectBox
            label={"Aktiv-Bereich:"}
            enumerator={WGRZuordnungWorkAreaEnumerator}
            id="aktiv-bereich"
            selection={aktivArea}
            getSelectedValue={(value) =>
              value ? activeAreaChangeHandler(value) : null
            }
            TypographyProps={{ className: "wgrzuordnung__TextStyle" }}
          />
        </Grid>
        {aktivArea === WGR_SEARCH && (
          <Grid item xs={12}>
            <WGRKurztextFilter
              label="Kurztext:"
              buttonCaption="Suchen"
              applyFilterWhileTyping
              applyFilter={getRecordsByKurtzText}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          {aktivArea === WGR_OHNE_WGR && (
            <DataGrid
              height={windowViewport.height}
              columnDefs={WGRZuordnungOhneWGRColumnDefs}
              rowsDataDef={withoutWGRArea}
              onRowClicked={rowClickHandler}
              exportDef={
                (withoutWGRArea.data?.length ?? 0) > 0
                  ? {
                      action: ExportService.positionenOhneWgr,
                    }
                  : undefined
              }
              gridActions={
                <Typography variant={"h1"}>
                  {WGRZuordnungOhneWGRCaption}
                </Typography>
              }
            />
          )}
          {aktivArea === WGR_MIT_WGR && (
            <DataGrid
              height={windowViewport.height}
              columnDefs={WGRZuordnungColumnDefs}
              rowsDataDef={withWGRArea}
              gridActions={
                <Typography variant={"h1"}>
                  {WGRZuordnungMitWGRCaption}
                </Typography>
              }
              onRowClicked={rowClickHandler}
            />
          )}
          {aktivArea === WGR_SEARCH && (
            <DataGrid
              height={windowViewport.height - searchTextGap}
              columnDefs={WGRZuordnungColumnDefs}
              rowsDataDef={searchAreaData}
              gridActions={
                <Typography variant={"h1"}>
                  {WGRZuordnungMitWGRCaption}
                </Typography>
              }
              onRowClicked={rowClickHandler}
            />
          )}
        </Grid>
      </Grid>
      {dialog && <ModalMessageDialog {...dialog} />}
    </>
  );
};

export default WGRZuordnung;
