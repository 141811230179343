import { Button, Grid, Stack, Typography } from "@mui/material";
import ModalMessageDialog from "global/components/UI/MessageDialogs/ModalMessageDialog";
import RemoteSearchSelectBox from "global/components/UI/SelectBox/RemoteSearchSelectBox";
import TextValue from "global/components/UI/TextValue/TextValue";
import UICheckbox from "global/components/UI/UICheckbox/UICheckbox";
import {
  RNS_GLN_LIST,
  useEnumerator,
} from "global/hook/enumerator/use-enumerator";
import useModalController, {
  useModalDialog,
} from "global/hook/modal/use-modals";
import MESSAGES from "global/messages";
import { ChangeEvent, useState } from "react";
import { Rns } from "service/data-service/automate-controller/interface/AutomatenblattInformation";
import PositionService from "service/sap-service/Position.service";
import SapInputParam from "service/sap-service/interface/SapInputParam";
import SapOutputParam, {
  PositionDTO,
} from "service/sap-service/interface/SapOutputParam";
import GLNListDialogContent from "./GLNListDialogContent";

export interface PositionDetailsDialogContentProps {
  onContentChange: Function;
  entry: PositionDTO;
}

const PositionOhneGlnDetailsDialogContent = (
  props: PositionDetailsDialogContentProps
) => {
  const modals = useModalController();
  const [dialogHelper, dialogController] = useModalDialog(
    "gln-zuordnen-helper"
  );
  const [rnsGln, setRnsGln] = useState<string>("");
  const [nonEpcom, setNonEpcom] = useState<boolean>(false);
  const [rns, setRns] = useState<Rns | undefined>();

  const rnsGlnFilteredEnumerator = useEnumerator(RNS_GLN_LIST, true);

  const rnsGlnTextChangeHandler = (value: string) => {
    setRnsGln(value);
    props.onContentChange({ rnsGlnChanged: undefined });
  };

  const onPlzSucheGLNHandler = (value: string) => {
    dialogController.closeDialog();
    setRnsGln(value);
    checkRnsGln(value);
  };

  const checkRnsGln = (value: string) => {
    props.onContentChange({ rnsGlnChanged: undefined });
    PositionService.getRnsDataForGln(
      { rnsGln: value } as SapInputParam,
      (output: SapOutputParam) => {
        if (output.errorTyp === MESSAGES.ERR_NO_ERROR) {
          setRns(output.rns);
          props.onContentChange({ rnsGlnChanged: value });
        } else {
          setRns({} as Rns);
          modals.showMessageDialog(
            output.message,
            undefined,
            MESSAGES.DIALOG_TITLE_ERROR
          );
        }
      }
    );
  };

  const showGLNsListDialog = () => {
    dialogController.showDialog({
      message: "",
      content: (
        <GLNListDialogContent
          zipcode={props.entry.rnsPlz}
          onGLNSelected={onPlzSucheGLNHandler}
        />
      ),
      onAbortClick: () => {},
      abortCaption: MESSAGES.BUTTON_CANCEL,
      maxWidth: "xl",
    });
  };

  const checkRnsButtonHandler = () => {
    checkRnsGln(rnsGln);
  };

  const onNonEpcomCheckboxHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setNonEpcom(event.target.checked);
    props.onContentChange({ knzNotEpcom: event.target.checked });
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextValue label="Kunde-GLN:" value={props.entry.kundeGln} readOnly />
        </Grid>
        <Grid item xs={12}>
          <TextValue label="RNS-Name:" value={props.entry.rnsName} readOnly />
        </Grid>
        <Grid item xs={12}>
          <TextValue label="RNS-PLZ:" value={props.entry.rnsPlz} readOnly />
        </Grid>
        <Grid item xs={12}>
          <TextValue label="RNS-Ort:" value={props.entry.rnsOrt} readOnly />
        </Grid>
        <Grid item xs={12}>
          <TextValue
            label="RNS-Strasse:"
            value={props.entry.rnsStrasse}
            readOnly
          />
        </Grid>
        <Grid item xs={12}>
          <UICheckbox
            label="Kein EPCOM-Teilnehmer:"
            value={nonEpcom}
            id="non-epcom-teilnehmer"
            onChange={onNonEpcomCheckboxHandler}
          ></UICheckbox>
        </Grid>

        <Grid item xs={12}>
          <RemoteSearchSelectBox
            label="RNS-GLN:"
            selection={rnsGln}
            enumerator={rnsGlnFilteredEnumerator}
            id="rnsglnAutocomplete"
            getSelectedValue={(newValue) => {
              rnsGlnTextChangeHandler(newValue ?? "");
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Stack
            direction="row"
            justifyContent={"right"}
            spacing={3}
            mt={1}
            mb={3}
          >
            <Button
              className="dialogButton"
              variant="contained"
              onClick={checkRnsButtonHandler}
              disabled={nonEpcom}
            >
              RNS-GLN prüfen
            </Button>
            <Button
              className="dialogButton"
              variant="contained"
              onClick={showGLNsListDialog}
              disabled={nonEpcom}
            >
              PLZ Suche
            </Button>
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h3">Rücknehmerstelle (RNS)</Typography>
        </Grid>

        <Grid item xs={12}>
          <TextValue
            label="Name:"
            value={nonEpcom ? "" : rns?.name ?? ""}
            readOnly
          />
        </Grid>

        <Grid item xs={12}>
          <TextValue
            label="Strasse:"
            value={nonEpcom ? "" : rns?.strasse ?? ""}
            readOnly
          />
        </Grid>
        <Grid item xs={12}>
          <TextValue
            label="PLZ:"
            value={nonEpcom ? "" : rns?.plz ?? ""}
            readOnly
          />
        </Grid>
        <Grid item xs={12}>
          <TextValue
            label="Ort:"
            value={nonEpcom ? "" : rns?.ort ?? ""}
            readOnly
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h3">Rücknehmer (RN)</Typography>
        </Grid>

        <Grid item xs={12}>
          <TextValue
            label="GLN:"
            value={nonEpcom ? "" : rns?.edekaOrganisation?.gln ?? ""}
            readOnly
          />
        </Grid>
        <Grid item xs={12}>
          <TextValue
            label="Name:"
            value={nonEpcom ? "" : rns?.edekaOrganisation?.name ?? ""}
            readOnly
          />
        </Grid>
      </Grid>
      {dialogHelper && <ModalMessageDialog {...dialogHelper} />}
    </>
  );
};

export default PositionOhneGlnDetailsDialogContent;
