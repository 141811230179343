import { Grid, Stack, Typography } from "@mui/material";
import DataGrid from "global/components/UI/DataGrid/DataGrid";
import { CACHE_LIFESPAN } from "global/components/UI/DataGrid/hooks/use-data-cache";
import { useTabs } from "global/hook/tabs/use-tabs";
import ExportService from "service/report-service/export/Export.service";
import DsmdService from "service/ruecknehmer-service/info-controller/Dsmd.service";
import AutomatDatenDsmd from "service/ruecknehmer-service/info-controller/interface/AutomatDatenDsmd";
import DsmdColumnDefs from "./grid-column-defs";

const DsmdFehler: React.FC = () => {
  const [, , tabsController] = useTabs();

  return (
    <Stack className="erreichbarkeitRecords">
      <Grid container spacing={1} width={"auto"}>
        <Grid item xs={12} mt={3}>
          <Typography variant={"h1"}>Fehlerhafte DSMD-Verbindungen</Typography>
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            height={430}
            columnDefs={DsmdColumnDefs}
            onRowClicked={(row: AutomatDatenDsmd) => {
              tabsController.open(row.serienNr);
            }}
            exportDef={{ action: ExportService.downloadRnBTBDSMDFailedToday }}
            rowsDataDef={{
              uncontrolledDataFetchCall: DsmdService.getFailedDsmd,
              cacheProps: {
                cacheKey: "fehlerhafte-dsmd-verbindungen-records",
                expireAgeInSeconds: 5,
                lifespan: CACHE_LIFESPAN.LONG,
              },
            }}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default DsmdFehler;
