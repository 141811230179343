import { Typography } from "@mui/material";

import { AgGridReact } from "ag-grid-react";
import DataGrid from "global/components/UI/DataGrid/DataGrid";
import { RowsDataDefinition } from "global/components/UI/DataGrid/types";
import { useCallback, useRef } from "react";
import JobMonitoringHistoryDTO from "service/data-service/job-controller/interface/JobMonitoringHistoryDTO";
import JobMonitoringHistoryColumnDefs from "../grid/grid-column-history-defs";
import GRID_OPTIONS from "../grid/grid-options";
import { JobMonitoringDetails } from "./JobDetailsDialogContent";

const JobMonitoringSearch = (props: {
  gridDataDef: RowsDataDefinition<JobMonitoringHistoryDTO>;
  gridHeight: number;
  rowClickHandler: (entry: JobMonitoringDetails) => void;
}) => {
  const gridRef = useRef<AgGridReact>(null);

  const autoSizeAll = useCallback(() => {
    const allColumnIds: string[] = [];
    gridRef.current!.columnApi.getAllDisplayedColumns()!.forEach((column) => {
      allColumnIds.push(column.getId());
    });
    gridRef.current!.columnApi.autoSizeColumns(allColumnIds, false);
  }, []);

  return (
    <DataGrid
      gridActions={
        <Typography variant={"h1"}>Job Monitoring - Datenbank Suche</Typography>
      }
      gridOptions={GRID_OPTIONS}
      height={props.gridHeight}
      columnDefs={JobMonitoringHistoryColumnDefs}
      rowsDataDef={props.gridDataDef}
      gridRef={gridRef}
      onGridReady={() => {
        autoSizeAll();
      }}
      onRowClicked={props.rowClickHandler}
    />
  );
};

export default JobMonitoringSearch;
