import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import DataGrid from "global/components/UI/DataGrid/DataGrid";
import StatusIconElement, {
  StatusIconType,
} from "global/components/UI/StatusIcon/StatusIcon";
import TextValue from "global/components/UI/TextValue/TextValue";
import { useCallback, useEffect, useRef, useState } from "react";
import UnplausibleRechnungenService from "service/sap-service/UnplausibleRechnungen.service";
import { RechnungItem } from "../controller/UnplausibleRechnungenController";

export interface UnplausibleRechnungAddDialogContentProps {
  onSelectionChange: Function;
}

const UnplausibleRechnungAddDialogContent = (
  props: UnplausibleRechnungAddDialogContentProps
) => {
  const [referenz, setReferenz] = useState<string>("");
  const [rechnungItems, setRechnungItems] = useState<Array<RechnungItem>>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [selection, setSelection] = useState<Array<string>>([]);
  const [refreshTrigger, setRefreshTrigger] = useState<boolean>(false);

  const gridRef = useRef<AgGridReact>(null);

  const getRowId = useCallback((params: any) => String(params.data.id), []);

  const RechnungItemsColumnDefs: Array<ColDef> = [
    {
      headerName: "ID",
      field: "selected",
      maxWidth: 90,
      minWidth: 90,
      headerComponent: (displayName: string) => {
        return (
          <Box className="box-with-centered-content">
            <Checkbox
              checked={
                selection.length > 0 &&
                selection.length === rechnungItems.length
              }
              color="success"
              onChange={(_, value) => {
                switchSelection(value);
              }}
            />
          </Box>
        );
      },
      cellClass: "ag-cell-center-justified-content",
      cellRenderer: (params: any) => {
        return (
          <Box className="box-with-centered-content">
            <Checkbox
              checked={params.data.selected}
              color="success"
              onChange={(_, value) => {
                params.data.selected = value;
                updateSelection(params.data.id, value);
              }}
            />
          </Box>
        );
      },
    },

    {
      headerName: "Description",
      field: "description",
      minWidth: 300,
    },
  ];

  const updateSelection = (id: string, value: boolean) => {
    if (value) {
      setSelection((prev) => [...prev, id]);
    } else {
      setSelection((prev) => prev.filter((e) => e !== id));
    }
    setRefreshTrigger((prev) => !prev);
  };

  const switchSelection = (value: boolean) => {
    if (gridRef.current?.api) {
      const newSelection: Array<string> = [];
      gridRef.current.api.forEachNode((node) => {
        node.data.selected = value;
        if (value) {
          newSelection.push(node.data.id);
        }
      });
      setSelection(newSelection);
    }
    setRefreshTrigger((prev) => !prev);
  };

  const onRechnungItemsReceived = useCallback((items: Array<RechnungItem>) => {
    setRefreshing(false);
    setSelection([]);
    setErrorMessage("");
    setRechnungItems(items as Array<RechnungItem>);
  }, []);

  const onRechnungItemsRetrieveError = useCallback((errorMessage: string) => {
    setRechnungItems([] as Array<RechnungItem>);
    setSelection([]);
    setRefreshing(false);
    setErrorMessage(errorMessage);
  }, []);

  const retrieveRechnungItems = () => {
    setRefreshing(true);
    UnplausibleRechnungenService.findPositions(referenz)
      .then((items) => onRechnungItemsReceived(items as Array<RechnungItem>))
      .catch((errorMessage) => onRechnungItemsRetrieveError(errorMessage));
  };

  useEffect(() => {
    props.onSelectionChange(referenz, selection);
  }, [selection]);

  useEffect(() => {
    if (!gridRef.current?.api) {
      return;
    }
    // update headers
    const columnDefs = [...RechnungItemsColumnDefs];
    columnDefs[0].headerComponent = (displayName: string) => {
      return (
        <Box className="box-with-centered-content">
          <Checkbox
            checked={
              selection.length > 0 && selection.length === rechnungItems.length
            }
            color="success"
            onChange={(_, value) => {
              switchSelection(value);
            }}
          />
        </Box>
      );
    };
    gridRef.current.api.setGridOption("columnDefs", columnDefs);
    gridRef.current.api.refreshCells({ force: true });
  }, [refreshTrigger]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {refreshing && (
            <CircularProgress
              color="primary"
              className="dialog__SuchenProgress"
              variant={"indeterminate"}
            />
          )}
          <Stack className="dialog__SuchenStack" direction="row" spacing={2}>
            <TextValue
              label="Regulierungs Beleg:"
              placeholder="enter a Regulierungs Beleg"
              value={referenz}
              TypographyProps={{ className: "dialog__ReferenzLabel" }}
              onChange={(e) => setReferenz(e.target.value)}
            />
            <Button
              className="dialog__SuchenButton"
              variant="contained"
              size="small"
              onClick={retrieveRechnungItems}
              disabled={referenz.length < 3}
            >
              Suchen
            </Button>
          </Stack>
          {errorMessage && (
            <Stack className="dialog__SuchenError" direction="row" spacing={2}>
              <StatusIconElement type={StatusIconType.RN} />
              <Typography className="dialogTextErrorStyle">
                {errorMessage}
              </Typography>
            </Stack>
          )}
        </Grid>

        <Grid item xs={12}>
          <DataGrid
            height={300}
            columnDefs={RechnungItemsColumnDefs}
            rowsDataDef={{ data: rechnungItems }}
            gridRef={gridRef}
            gridOptions={{
              getRowId: getRowId,
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default UnplausibleRechnungAddDialogContent;
