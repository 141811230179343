import { Box, Button, Grid, Stack } from "@mui/material";
import { AccessContext } from "App/components/Access-Control/AccessProvider";
import DataGrid from "global/components/UI/DataGrid/DataGrid";
import {
  AUTOMAT_KAUFANFRAGENSTATUS_ENUM_ID,
  useEnumerator,
} from "global/hook/enumerator/use-enumerator";
import {
  KAUFANFRAGE_GRID_SELECTION,
  KAUFANFRAGE_STATUS_ITEMS,
  KAUFANFRAGE_STATUS_SEARCH,
  useKaufanfragen,
} from "global/hook/kaufanfragen/use-kaufanfragen-store";
import { useContext, useEffect, useState } from "react";
import Kaufanfrage from "service/data-service/kaufanfragen-controller/interface/Kaufanfrage";
import KaufanfragenColumnDefs from "./grid-column-defs";

import ModalMessageDialog from "global/components/UI/MessageDialogs/ModalMessageDialog";
import MultipleSelectCheckbox from "global/components/UI/SelectBox/MultipleSelectCheckbox";
import useModalController, {
  useModalDialog,
} from "global/hook/modal/use-modals";
import { useWindowViewport } from "global/hook/windowViewport/use-window-viewport";
import MESSAGES from "global/messages";
import ExportService from "service/report-service/export/Export.service";
import KaufanfragenATHGridActions from "./component/KaufanfragenATHActions";
import KaufanfragenGBHGridActions from "./component/KaufanfragenGBHActions";
import "./kaufanfragen.scss";

const FILTER_DEFAULT: Array<string> = ["ANGEFRAGT"];
const FILTER_DEFAULT_ATH_USER: Array<string> = ["GENEHMIGT", "ABGELEHNT"];

const Kaufanfragen: React.FC = () => {
  const modals = useModalController();
  const [dialog, dialogController] = useModalDialog("kaufanfragen");

  const [kaufanfragenStore, kaufanfragenDispatcher] = useKaufanfragen();

  const statusEnumerator = useEnumerator(AUTOMAT_KAUFANFRAGENSTATUS_ENUM_ID);
  const accessContext = useContext(AccessContext);

  const [isATHUser, setIsATHUser] = useState(false);
  const [resetFilter, setResetFilter] = useState(false);

  const windowViewport = useWindowViewport(0, 268);

  useEffect(() => {
    setIsATHUser(accessContext.getAccessContainer().rollenTyp === "ATH");
  }, [accessContext]);

  useEffect(() => {
    resetFilters();
    filterHandler(); // eslint-disable-next-line
  }, [isATHUser, statusEnumerator]);

  function filterHandler() {
    kaufanfragenDispatcher(KAUFANFRAGE_STATUS_SEARCH);
  }

  const resetFilters = () => {
    let filterItems = isATHUser ? FILTER_DEFAULT_ATH_USER : FILTER_DEFAULT;
    const items = statusEnumerator.items.filter((e) =>
      filterItems.includes(e.id as string)
    );
    setResetFilter((state) => !state);
    kaufanfragenDispatcher(KAUFANFRAGE_STATUS_ITEMS, {
      queryStatusItems: items,
    });
  };

  function exportHandler() {
    ExportService.downloadKaufanfragen(
      kaufanfragenStore.statusItems.map((e) => e.id).join(",")
    );
  }

  return (
    <Stack columnGap={2}>
      <Grid container spacing={2} width={"auto"}>
        <Grid item xs={12}>
          <Box className="uiElement mt_1rem ml_1rem">
            <MultipleSelectCheckbox
              limitTags={5}
              label="Status:"
              defaultValues={
                isATHUser ? FILTER_DEFAULT_ATH_USER : FILTER_DEFAULT
              }
              id="status"
              options={statusEnumerator.items}
              onChange={(values) => {
                kaufanfragenDispatcher(KAUFANFRAGE_STATUS_ITEMS, {
                  queryStatusItems: values,
                });
              }}
              resetSelectedValues={resetFilter}
              AutocompleteProps={{
                className: "inputTextFieldForKaufanfragenStatus",
              }}
              TypographyProps={{ className: "dialogTextStyle" }}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Stack className="rowStack filterButtons">
            <Button variant="secondary" onClick={resetFilters}>
              {MESSAGES.BUTTON_RESET}
            </Button>
            <Button variant="contained" onClick={filterHandler}>
              {MESSAGES.BUTTON_APPLY_FILTER}
            </Button>
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <DataGrid
            height={windowViewport.height}
            columnDefs={KaufanfragenColumnDefs}
            rowsDataDef={{ data: kaufanfragenStore.result?.Row ?? [] }}
            exportDef={{ action: exportHandler }}
            gridActions={[
              !isATHUser && (
                <KaufanfragenGBHGridActions
                  key="kaufanfragenGBHGridActions"
                  gridSelection={kaufanfragenStore.gridSelection}
                  modals={modals}
                  dialogController={dialogController}
                  kaufanfragenDispatcher={kaufanfragenDispatcher}
                />
              ),
              isATHUser && (
                <KaufanfragenATHGridActions
                  key="kaufanfragenATHGridActions"
                  gridSelection={kaufanfragenStore.gridSelection}
                  modals={modals}
                  dialogController={dialogController}
                  kaufanfragenDispatcher={kaufanfragenDispatcher}
                />
              ),
            ]}
            onRowSelected={(record?: Kaufanfrage) =>
              kaufanfragenDispatcher(KAUFANFRAGE_GRID_SELECTION, {
                gridSelection: record,
              })
            }
          />
        </Grid>
      </Grid>
      {dialog && <ModalMessageDialog {...dialog} />}
    </Stack>
  );
};

export default Kaufanfragen;
