import { Button } from "@mui/material";
import MESSAGES from "global/messages";
import KaufanfragenGBHController from "../controller/KaufanfragenGBHController";
import KaufanfragenUtil from "../controller/KaufanfragenUtil";
import { KaufanfragenGridActionProps } from "../interface/Kaufanfragen";
import KaufanfragenGBHGrundDialogContent from "./KaufanfragenGBHRejectDialog";

const KaufanfragenGBHGridActions = (props: KaufanfragenGridActionProps) => {
  let rejectReason: string | undefined = undefined;

  const onRejectReasonTextChange = (value: string | undefined) => {
    rejectReason = value;
  };

  const showKaufanfrageRejectDialog = () => {
    onRejectReasonTextChange(undefined);
    if (KaufanfragenUtil.isGridSelectionSuitable(props, "ANGEFRAGT")) {
      props.dialogController.showDialog({
        message: "",
        title: MESSAGES.KAUFANFRAGEN_REJECT_TITLE,
        content: (
          <KaufanfragenGBHGrundDialogContent
            onReasonTextChange={onRejectReasonTextChange}
          />
        ),
        onOkClick: () => {
          doReject();
        },
        okCaption: MESSAGES.KAUFANFRAGEN_REJECT_TITLE,
        onAbortClick: () => {},
        abortCaption: MESSAGES.BUTTON_CANCEL,
      });
    }
  };

  const doReject = () => {
    KaufanfragenGBHController.reject(props, rejectReason);
  };
  const doApprove = () => {
    KaufanfragenGBHController.approve(props);
  };

  return (
    <>
      <Button size="small" variant="contained" onClick={doApprove}>
        {MESSAGES.BUTTON_APPROVE}
      </Button>
      <Button
        size="small"
        variant="secondary"
        onClick={showKaufanfrageRejectDialog}
      >
        {MESSAGES.BUTTON_REJECT}
      </Button>
    </>
  );
};

export default KaufanfragenGBHGridActions;
