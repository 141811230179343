import { Grid, Stack, Typography } from "@mui/material";
import DataGrid from "global/components/UI/DataGrid/DataGrid";
import { CACHE_LIFESPAN } from "global/components/UI/DataGrid/hooks/use-data-cache";
import { useTabs } from "global/hook/tabs/use-tabs";
import { useWindowViewport } from "global/hook/windowViewport/use-window-viewport";
import ErreichbarkeitService from "service/data-service/reporting-controller/Erreichbarkeit.service";
import { Erreichbarkeit } from "service/data-service/reporting-controller/interface/Erreichbarkeit";
import ExportService from "service/report-service/export/Export.service";
import ErreichbarkeitColumnDefs from "./grid-column-defs";

const KommArt: React.FC = () => {
  const windowViewport = useWindowViewport(0, 210);
  const [, , tabsController] = useTabs();

  return (
    <Stack className="erreichbarkeitRecords">
      <Grid container spacing={1} width={"auto"}>
        <Grid item xs={12} mt={3}>
          <Typography variant={"h1"}>Erreichbarkeiten je KommArt</Typography>
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            height={windowViewport.height}
            columnDefs={ErreichbarkeitColumnDefs}
            onRowClicked={(row: Erreichbarkeit) => {
              tabsController.open(row.serienNr, row.automatKey);
            }}
            exportDef={{ action: ExportService.downloadErreichbarkeit }}
            rowsDataDef={{
              uncontrolledDataFetchCall: ErreichbarkeitService.getReport,
              cacheProps: {
                cacheKey: "erreichbarkeit-records",
                expireAgeInSeconds: 60 * 60,
                lifespan: CACHE_LIFESPAN.SHORT,
              },
            }}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};
export default KommArt;
