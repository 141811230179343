import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
} from "@mui/material";
import { AccessContext } from "App/components/Access-Control/AccessProvider";
import {
  RNS_GLN_LIST,
  useEnumerator,
} from "global/hook/enumerator/use-enumerator";

import DataGrid from "global/components/UI/DataGrid/DataGrid";
import DatePickerElement from "global/components/UI/DatePicker/DatePicker";
import RemoteSearchSelectBox from "global/components/UI/SelectBox/RemoteSearchSelectBox";
import TextValue from "global/components/UI/TextValue/TextValue";
import UIButton from "global/components/UI/UIButton/UIButton";
import useModalController from "global/hook/modal/use-modals";
import DateUtils, { getTomorrow } from "global/util/DateUtils";

import MESSAGES from "global/messages";
import { generateDefaultUpdateValues } from "page/Automatensuche/components/Automatenblatt/script/clearUpdateValues";
import { useContext, useState } from "react";
import AutomatenUpdateRequest from "service/data-service/automate-controller/interface/AutomatenUpdateRequest";
import AutomatenblattInformation from "service/data-service/automate-controller/interface/AutomatenblattInformation";
import RnsSearchResult from "service/data-service/rns-controller/interface/RnsSearchResult";
import { generateDefaultRnsSearchResult } from "./generator/defaultObjectGenerators";
import "./glnChange.scss";
import {
  MachineIdentifier,
  OtherMachineToSave,
} from "./interface/otherMachinesToSave";
import GlnChangeController from "./script/GlnChangeController";
import createGlnChangeColumnDefs from "./script/columnDefinition";

interface GlnChangeProps {
  automatenblattInformation: AutomatenblattInformation;
  onGlnChange: () => void;
}

const GlnChange = (props: GlnChangeProps) => {
  const [open, setOpen] = useState(false);
  const [glnToSearch, setGlnToSearch] = useState("");
  const [searchResult, setSearchResult] = useState<RnsSearchResult>(
    generateDefaultRnsSearchResult()
  );
  const [gueltigVonDefaultTomorrow] = useState(getTomorrow());
  const [otherMachinesToSave, setOtherMachinesToSave] = useState<
    Array<OtherMachineToSave>
  >([]);

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);

  const modalController = useModalController();
  const accessContext = useContext(AccessContext);

  const rnsGlnFilteredEnumerator = useEnumerator(RNS_GLN_LIST, true);

  function addMachineToSaveableMachineArray(machineToSave: MachineIdentifier) {
    setOtherMachinesToSave((state) => {
      return [...state, { ...machineToSave, saveAlso: false }];
    });
  }

  function setMachineWillBeSaved(serialNumber: string, newValue: boolean) {
    setOtherMachinesToSave((state) => {
      let indexOfMachineToSave = state.findIndex((value) => {
        return serialNumber === value.serialNumber;
      });

      state[indexOfMachineToSave].saveAlso = newValue;
      return state;
    });
  }

  function collectOtherMachinesToSaveData() {
    if (props.automatenblattInformation.aktiveSerienNrInRns !== "") {
      GlnChangeController.searchSerialNumbersForOtherMachinesOfRns(
        props.automatenblattInformation.aktiveSerienNrInRns,
        props.automatenblattInformation.seriennummer.toString(),
        addMachineToSaveableMachineArray
      );
    }
  }

  const openDialog = () => {
    collectOtherMachinesToSaveData();
    setOpen(true);
  };

  function closeDialog() {
    setSelectedDate(null);
    setGlnToSearch("");
    clearSearchResult();
    setOtherMachinesToSave([]);
    setOpen(false);
  }

  function handleSave() {
    GlnChangeController.updateMachineWithNewGln(
      GlnChangeController.filterMachinesToSave(otherMachinesToSave),
      prepareUpdateRequest(),
      props.onGlnChange
    );
    closeDialog();
  }

  function clearSearchResult() {
    if (!saveButtonDisabled) {
      setSaveButtonDisabled(true);
      setSearchResult(generateDefaultRnsSearchResult());
    }
  }

  function prepareUpdateRequest(): AutomatenUpdateRequest {
    const updateReq: AutomatenUpdateRequest = generateDefaultUpdateValues(
      props.automatenblattInformation
    );
    updateReq.rnsGlnForRnsWechsel = glnToSearch;
    updateReq.datumVonForRnsWechsel =
      DateUtils.formatDateToAPIDateString(
        selectedDate ?? gueltigVonDefaultTomorrow
      ) ?? undefined;

    return updateReq;
  }

  function onSeachConcluded(value: RnsSearchResult) {
    GlnChangeController.showDialogWhenOtherGh(value, modalController);
    setSearchResult(value);
    setSaveButtonDisabled(false);
  }

  return (
    <Stack flexDirection="row" columnGap={2}>
      <Button
        className="glnChangeButton"
        variant="contained"
        size="small"
        onClick={openDialog}
        disabled={
          !accessContext.getAccessContainer().showAutomatenTabBtnGlnWechsel ||
          props.automatenblattInformation.readonly
        }
      >
        {MESSAGES.BUTTON_OTHER_RNS_GLN}
      </Button>

      <Dialog open={open} onClose={closeDialog}>
        <DialogTitle>RNS ändern</DialogTitle>
        <DialogContent className="glnSearchHeight">
          <RemoteSearchSelectBox
            label={MESSAGES.GLN_CHANGE_RNS_SEARCH_LABEL}
            enumerator={rnsGlnFilteredEnumerator}
            id="rnsglnAutocomplete"
            getSelectedValue={(newValue) => {
              setGlnToSearch(newValue ?? "");
              clearSearchResult();
            }}
          />
        </DialogContent>
        <DialogActions className="searchAction">
          <UIButton onClick={closeDialog} label={MESSAGES.BUTTON_CANCEL} />
          <UIButton
            onClick={() =>
              GlnChangeController.searchRns(
                glnToSearch,
                props.automatenblattInformation.rns.gln.toString(),
                onSeachConcluded,
                modalController
              )
            }
            label={MESSAGES.BUTTON_RNS_SEARCH}
          />
        </DialogActions>
        <DialogContent>
          <DialogContentText>Neue Rücknahmestelle (RNS)</DialogContentText>
          <TextValue
            label="Name:"
            value={searchResult.rows[0].name}
            id="rns-name"
            readOnly
          />
          <TextValue
            label="Strasse:"
            value={searchResult.rows[0].strasse}
            id="rns-strasse"
            readOnly
          />
          <TextValue
            label="PLZ:"
            value={searchResult.rows[0].plz}
            id="rns-plz"
            readOnly
          />
          <TextValue
            label="Ort:"
            value={searchResult.rows[0].ort}
            id="rns-ort"
            readOnly
          />
          <DialogContentText marginTop={1}>
            Neuer Rücknehmer (RN)
          </DialogContentText>
          <TextValue
            label="GLN:"
            value={searchResult.rows[0].edekaOrganisation.gln}
            id="rns-gln"
            readOnly
          />
          <TextValue
            label="Name:"
            value={searchResult.rows[0].edekaOrganisation.name}
            id="rns-name"
            readOnly
          />
          <DatePickerElement
            label="Gültig von:"
            defaultValue={gueltigVonDefaultTomorrow}
            getSelectedValue={(value) => {
              setSelectedDate(new Date(value ?? getTomorrow()));
            }}
            id="rns-gultig-von"
            datePickerProperties={{
              disablePast: true,
            }}
          />
          <DialogContentText marginTop={1}>
            Weitere Automaten des alten Rücknehmers
          </DialogContentText>
          <Grid item gridColumn="span 2" xs={12}>
            <DataGrid
              height={138}
              columnDefs={createGlnChangeColumnDefs(setMachineWillBeSaved)}
              rowsDataDef={{ data: otherMachinesToSave }}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <UIButton
            onClick={handleSave}
            label={MESSAGES.BUTTON_RNS_SAVE}
            disabled={saveButtonDisabled}
          />
        </DialogActions>
      </Dialog>
    </Stack>
  );
};
export default GlnChange;
