import { EnumeratorItem } from "global/components/EnumeratedValues/Enumerator";
import ModalController from "global/hook/modal/interface/ModalController";
import MESSAGES from "global/messages";
import AnwendernachrichtService from "service/data-service/anwender-controller/Anwendernachricht.service";
import Anwendernachricht from "service/data-service/anwender-controller/interface/Anwendernachricht";

const AnwendernachrichtenUtil = {
  buildHoursIntervalsItems: (): Array<EnumeratorItem> => {
    const items: Array<EnumeratorItem> = [];

    const addHourIntervalItem = (hour: number, isHalfTimeEntry = false) => {
      items.push({
        id: AnwendernachrichtenUtil.buildHoursIntervalsId([
          hour,
          isHalfTimeEntry ? 30 : 0,
        ]),
        value: AnwendernachrichtenUtil.buildHoursIntervalsValue([
          hour,
          isHalfTimeEntry ? 30 : 0,
        ]),
      } as EnumeratorItem);
    };
    for (let hour = 6; hour < 21; hour++) {
      addHourIntervalItem(hour);
      if (hour < 20) {
        addHourIntervalItem(hour, true);
      }
    }
    return items;
  },
  buildHoursIntervalsId: (data: number[] | undefined): number => {
    if (data && data.length > 1) {
      return data[0] * 100 + data[1];
    }
    return 0;
  },
  buildHoursIntervalsValue: (data: number[] | undefined): string => {
    if (data && data.length > 1) {
      let start = `${data[0]}`.padStart(2, "0");
      let end = `${data[1]}`.padStart(2, "0");
      return `${start}:${end}`;
    }
    return "";
  },
  transmuteToTimeArray: (
    value: EnumeratorItem | null
  ): Array<number> | undefined => {
    if (value) {
      return [
        Math.floor((value.id as number) / 100),
        (value.id as number) % 100,
      ];
    }
    return undefined;
  },
  delete: (
    record: Anwendernachricht,
    reloadTrigger: Function,
    modals: ModalController
  ) => {
    AnwendernachrichtService.update(
      {
        key: record.key,
        delete: true,
      },
      () => {
        modals.showMessageDialog(MESSAGES.ANWENDER_DELETE_CONFIRMATION);
        reloadTrigger();
      }
    );
  },
  update: (
    record: Anwendernachricht,
    reloadTrigger: Function,
    modals: ModalController
  ) => {
    AnwendernachrichtService.update(record, (dataRecord: Anwendernachricht) => {
      modals.showMessageDialog(MESSAGES.ANWENDER_SAVE_CONFIRMATION);
      reloadTrigger(dataRecord.key);
    });
  },
};

export default AnwendernachrichtenUtil;
