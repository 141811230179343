import { Button, Chip, Stack } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { useTransponder } from "global/hook/transponder/use-transponder";
import MESSAGES from "global/messages";
import { autoSizeColumns } from "global/util/gridUtils/autoSizeColumns";
import Row from "page/Automatensuche/interface/RowTypes";
import { useRef, useState } from "react";
import DepositMachineDataService from "service/data-service/automate-controller/DepositMachineData.service";
import ExportService from "service/report-service/export/Export.service";
import DataGrid from "../DataGrid/DataGrid";
import FilterInput from "../FilterSystem/FilterInput";
import FilterController from "../FilterSystem/controller/FilterController";
import { DepositMachineContainer } from "./container/DepositMachineContainer";
import "./depositMachineList.scss";

interface props {
  onCellClicked(data: Row): any;
  navigateToAutomat(
    serialNumber: string,
    automatKey: string,
    panelIndex: number
  ): void;
}

const DepositMachineList = (props: props) => {
  const [machineData, setMachineData] = useState<Array<Row>>([]);
  const [resetFilters, setResetFilters] = useState<boolean>(false);

  const dataGridRef = useRef<AgGridReact>(null);

  const [automatInfo, transponderCtrl] = useTransponder();

  function getDepositMachineList() {
    DepositMachineDataService.retrieveDepositMachineData(
      FilterController.getFilterObject(),
      retrieveMachineList,
      callbackOnFail
    );
  }

  function retrieveMachineList(data: Array<Row>) {
    setMachineData(data);

    DepositMachineContainer.splice(0);

    data.forEach((row) => {
      DepositMachineContainer.push(row);
    });
  }

  function callbackOnFail(error: any) {
    console.warn(error);
  }

  const columnsNotToResize = [
    "region",
    "regionName",
    "rnsOrt",
    "automatModellName",
  ];

  function handleOnRowDataChanged() {
    if (dataGridRef.current?.api)
      setTimeout(
        () =>
          machineData.length === 0
            ? dataGridRef.current!.api.sizeColumnsToFit()
            : autoSizeColumns(dataGridRef, columnsNotToResize),
        200
      );
  }

  function navigateToAutomat(
    serialNumber: string,
    automatKey: string,
    panelIndex: number
  ) {
    props.navigateToAutomat(serialNumber, automatKey, panelIndex);
    transponderCtrl.clearData();
  }

  function handleTransponderData() {
    if (automatInfo?.ipAddress) {
      FilterController.resetFilterCache();
      FilterController.save("ipAddress", automatInfo.ipAddress);
      getDepositMachineList();
      transponderCtrl.clearData();
    } else if (automatInfo?.automatKey) {
      navigateToAutomat(
        automatInfo.seriennr,
        automatInfo.automatKey,
        automatInfo.panelIndex
      );
    }
  }

  function handleOnGridReady() {
    setMachineData([...DepositMachineContainer]);
    setTimeout(() => handleTransponderData(), 300);
  }

  return (
    <Stack rowGap={0.5}>
      <FilterInput
        resetFilters={resetFilters}
        onEnterKey={getDepositMachineList}
      />
      <Stack className="rowStack buttonStack" justifyContent="space-between">
        <Stack className="rowStack filterButtons">
          <Button
            variant="secondary"
            onClick={() => {
              setResetFilters(resetFilters ? false : true);
              FilterController.resetFilterCache();
            }}
          >
            {MESSAGES.BUTTON_RESET}
          </Button>
          <Button variant="contained" onClick={getDepositMachineList}>
            {MESSAGES.BUTTON_APPLY_FILTER}
          </Button>
        </Stack>
        <Stack className="rowStack">
          <Chip
            className="exportChip"
            size="small"
            variant="outlined"
            label={MESSAGES.BUTTON_EXCEL_EXPORT}
            clickable
            onClick={() => {
              ExportService.downloadSearchResult(
                FilterController.getFilterObject()
              );
            }}
          />
        </Stack>
      </Stack>

      <DataGrid
        dataGridRef={dataGridRef}
        dataToShow={machineData}
        onCellClicked={props.onCellClicked}
        onGridReady={handleOnGridReady}
        onRowDataChanged={() => {
          handleOnRowDataChanged();
        }}
      />
    </Stack>
  );
};

export default DepositMachineList;
