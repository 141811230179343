import { Button, Grid, Stack, Typography } from "@mui/material";
import { EnumeratorItem } from "global/components/EnumeratedValues/Enumerator";
import SelectBox from "global/components/UI/SelectBox/SelectBox";
import TextValue from "global/components/UI/TextValue/TextValue";
import useModalController from "global/hook/modal/use-modals";
import {
  isFalsyOrEmptyString,
  returnUndefinedIfOnlyWhitespace,
} from "global/util/utils";
import { useEffect, useState } from "react";
import EdekaOrgService from "service/data-service/edekaOrg-controller/EdekaOrg.service";
import { EdekaOrganisation } from "service/data-service/rns-controller/interface/RnsSearchResult";

import MESSAGES from "global/messages";
import "./anlagerechnungsnehmer.scss";
import RechnungnehmerRegionEnumerator from "./AnlagerechnungsnehmerRegionUtil";

const AnlageRechnungsnehmer = () => {
  const modals = useModalController();

  const [gln, setGln] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [region, setRegion] = useState<EnumeratorItem | null>();
  const [reset, setReset] = useState(false);
  const [saveIsDisabled, setSaveIsDisabled] = useState(true);

  const resetFilter = () => {
    setGln("");
    setName("");
    setRegion(null);
    setReset((state) => !state);
  };

  const btnSaveUserHandler = () => {
    if (!region || isFalsyOrEmptyString(name) || isFalsyOrEmptyString(gln)) {
      modals.showMessageDialog(MESSAGES.FILL_ALL_REQUIERD);
      return;
    }

    EdekaOrgService.createRechnungsnehmer(
      {
        name: name,
        gln: gln,
        region: region!.id as string,
        email: `${region!.source!.email}`,
      } as EdekaOrganisation,
      (respone: EdekaOrganisation) => {
        if (respone.errorTyp !== MESSAGES.ERR_NO_ERROR) {
          modals.showMessageDialog(
            respone.errorMessage,
            undefined,
            MESSAGES.DIALOG_TITLE_SAVE_NOT_OK
          );
        } else {
          resetFilter();
          modals.showMessageDialog(
            MESSAGES.ANLAGE_SAVE_CONFIRMATION,
            undefined,
            MESSAGES.DIALOG_TITLE_SAVE
          );
        }
      },
      () => {}
    );
  };
  useEffect(() => {
    setSaveIsDisabled(
      region === null ||
        returnUndefinedIfOnlyWhitespace(name) === undefined ||
        returnUndefinedIfOnlyWhitespace(gln) === undefined ||
        gln.length !== 13
    );
  }, [name, region, gln]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} mt={3}>
        <Typography variant={"h1"}>Anlage Rechnungsnehmer</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextValue
          id={"anlagerechnungsnehmer-name"}
          label="Name:"
          value={name ?? ""}
          maxLength={20}
          onChange={(event) => setName(event.target.value ?? "")}
          TypographyProps={{
            className: "anlageneueruser__LabelStyle",
          }}
          TextFieldProps={{ className: "anlagerechnungsnehmer__InputStyle" }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextValue
          id={"anlagerechnungsnehmer-gln"}
          label="GLN:"
          maxLength={50}
          value={gln ?? ""}
          onChange={(event) => setGln(event.target.value ?? "")}
          TypographyProps={{
            className: "anlageneueruser__LabelStyle",
          }}
          TextFieldProps={{ className: "anlagerechnungsnehmer__InputStyle" }}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectBox
          label={"Region:"}
          enumerator={RechnungnehmerRegionEnumerator}
          id="anlagerechnungsnehmer-region"
          getSelectedValue={(value) => setRegion(value)}
          reset={reset}
          TypographyProps={{
            className: "anlageneueruser__LabelStyle",
          }}
          AutocompleteProps={{
            className: "anlagerechnungsnehmer__InputStyle",
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" spacing={2}>
          <Button size="small" variant="secondary" onClick={resetFilter}>
            {MESSAGES.BUTTON_RESET}
          </Button>
          <Button
            disabled={saveIsDisabled}
            size="small"
            variant="contained"
            onClick={btnSaveUserHandler}
          >
            {MESSAGES.BUTTON_SAVE}
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default AnlageRechnungsnehmer;
