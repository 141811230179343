import { Grid, Stack, Typography } from "@mui/material";
import DataGrid from "global/components/UI/DataGrid/DataGrid";
import { DataGridProps } from "global/components/UI/DataGrid/types";
import { AutomatTransponderInfo } from "global/hook/transponder/interface/Transponder";
import { useTransponder } from "global/hook/transponder/use-transponder";
import { useWindowViewport } from "global/hook/windowViewport/use-window-viewport";
import MESSAGES from "global/messages";
import { HREF_AUTOMATENSUCHE } from "global/util/routes";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import IpBereichService from "service/data-service/automate-controller/IpBereich.service";
import IpBereich from "service/data-service/automate-controller/interface/IpBereich";
import ExportService from "service/report-service/export/Export.service";
import IpRangeColumnDefs from "./grid-column-defs";

const IpRange: React.FC = () => {
  const windowViewport = useWindowViewport(0, 268);
  const transponderCtrl = useTransponder()[1];
  const navigate = useNavigate();
  const [selection, setSelection] = useState<IpBereich>();
  //Navigate to Rechnungsdaten ATH search positions page
  const navigateToAutomatSearch = (entry: IpBereich) => {
    if (entry === selection) {
      transponderCtrl.setTransponderData({
        ipAddress: entry.ipRange,
      } as AutomatTransponderInfo);
      navigate(HREF_AUTOMATENSUCHE);
    }
  };

  const ipRangeDataGridProps: DataGridProps<IpBereich> = {
    height: windowViewport.height,
    columnDefs: IpRangeColumnDefs,
    exportDef: { action: ExportService.ipRange },
    onRowSelected: setSelection,
    onRowClicked: (entry: IpBereich) => navigateToAutomatSearch(entry),
    rowsDataDef: {
      uncontrolledDataFetchCall: IpBereichService.load,
    },
  } as any;

  return (
    <Stack className="ipRange">
      <Grid container spacing={5} width={"auto"}>
        <Grid item xs={12} mt={3}>
          <Typography variant={"h1"}>
            {MESSAGES.MENU_ADMIN_IP_BEREICH}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <DataGrid {...ipRangeDataGridProps} />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default IpRange;
