import ModalController from "global/hook/modal/interface/ModalController";
import MESSAGES from "global/messages";
import ArtikleSearchResult from "service/artikel-service/interface/ArtikleSearchResult";

export interface ArtikelSucheDisplayInfo {
  readonly artikelLangText?: string;
  readonly gueltigAb?: string;
  readonly gueltigBis?: string;
  readonly letzteRuecknahmeArtikelOfAutomat?: string;
  readonly letzteRuecknahmeAutomat?: string;
  readonly letzteRuecknhameArtikelAllg?: string;
}

/**
 * Represents the result of validating a GTIN (Global Trade Item Number).
 * @interface GTINValidationResult
 */
interface GTINValidationResult {
  /** Indicates whether the GTIN is valid. */
  isValid: boolean;
  /** Error message if the GTIN is invalid; empty string if valid. */
  errorText: string;
  /** The parsed GTIN number; 0 if invalid due to zero-starting or length rules. */
  gtin: number;
}

// Valid lengths for GTIN (8, 12, 13, or 14 characters)
const ValidGTINLengths: number[] = [8, 12, 13, 14];

type ArticleErrorType =
  | "INVALID_GTIN"
  | "INVALID_LENGTH_GTIN"
  | "ARTICLE_NOT_BILLED_YET"
  | "NO_DATA"
  | "ARTICLE_NOT_VALID_RIGHT_NOW"
  | "UNKNOWN"
  | "INVALID_DATE";

const ERROR_MESSAGES = {
  INVALID_GTIN: "Die angegebene GTIN ist nicht gültig.",
  INVALID_LENGTH_GTIN: "Die GTIN darf nur 8,12,13 oder 14 Zeichen lang sein.",
  ZERO_STARTING_GTIN: "Die GTIN darf nicht mit einer Null beginnen.",
  ARTICLE_NOT_BILLED_YET:
    "Der Automat wurde mit der aktuellen SSID noch nicht abgerechnet. Eine Aussage zur Rücknahme ist daher nicht möglich.",
  NO_DATA: "Es konnten keine Daten gefunden werden.",
  ARTICLE_NOT_VALID_RIGHT_NOW: "Der Artikel ist derzeit nicht gültig.",
  UNKNOWN: (gtin?: number, machineId?: string) =>
    `Ein unbekannter Fehler ist aufgetreten (GTIN: ${gtin} / SchlüsselID: ${machineId})`,
  INVALID_DATE:
    "Das in der Datenbank stehende Datum kann nicht formatiert werden.",
  DEFAULT:
    "Ein unbekannter Fehler ist aufgetreten. Bitte melden Sie sich beim technischen Support. ",
} as const;

const ArtikelSucheController = {
  getAbrechnungsinformationenErrorText(
    errorTyp: ArticleErrorType | string,
    gtin?: number,
    machineId: string = ""
  ) {
    const message = ERROR_MESSAGES[errorTyp as keyof typeof ERROR_MESSAGES];
    if (typeof message === "function") {
      return message(gtin, machineId);
    }
    return message || ERROR_MESSAGES.DEFAULT;
  },

  /**
   * Validates a GTIN string to ensure it represents a positive long integer (whole number > 0)
   * without leading zeros, with a length of exactly 8, 12, 13, or 14 characters.
   * Rejects decimals, negatives, and numbers beyond JavaScript's safe integer limit (2⁵³ - 1).
   *
   * @param {string} gtin - The GTIN string to validate (e.g., "12345678", "1234567890123").
   * @returns {GTINValidationResult} An object containing the validation result, error text,
   * and parsed GTIN number.
   *
   * @example
   * checkValidGTIN("12345678") // { isValid: true, errorText: "", gtin: 12345678 }
   * checkValidGTIN("1234567890123") // { isValid: true, errorText: "", gtin: 1234567890123 }
   * checkValidGTIN("12345") // { isValid: false, errorText: "INVALID_LENGTH_GTIN", gtin: 12345 }
   * checkValidGTIN("007") // { isValid: false, errorText: "ZERO_STARTING_GTIN", gtin: 0 }
   */
  checkValidGTIN(gtin: string): GTINValidationResult {
    // Convert to string and trim whitespace
    const decodedStr: string = String(gtin).trim();

    // Early rejection for strings that start with "0" or are just "0"
    if (
      decodedStr === "0" || // Reject single "0"
      decodedStr.startsWith("0") // Reject leading zeros (e.g., "007")
    ) {
      return {
        isValid: false,
        errorText: "ZERO_STARTING_GTIN",
        gtin: 0,
      };
    }

    if (!ValidGTINLengths.includes(decodedStr.length)) {
      return {
        isValid: false,
        errorText: "INVALID_LENGTH_GTIN",
        gtin: 0, // Return parsed number or 0 if NaN
      };
    }

    // Parse as a number
    const num: number = Number(decodedStr);

    // Validation:
    // - Check for NaN (non-numeric input)
    // - Ensure it’s an integer
    // - Ensure it’s positive
    // - Verify string matches parsed number (no truncation or decimal)
    // - Check within safe integer range
    if (
      isNaN(num) || // Non-numeric like "abc"
      !Number.isInteger(num) || // Rejects decimals like "12.34"
      num <= 0 || // Rejects negatives and zero
      num.toString() !== decodedStr || // Ensures exact match (no formatting changes)
      num > Number.MAX_SAFE_INTEGER // Rejects numbers beyond 2⁵³ - 1
    ) {
      return {
        isValid: false,
        errorText: "INVALID_GTIN",
        gtin: num, // Return parsed value (may be NaN or invalid)
      };
    }

    return {
      isValid: true,
      errorText: "",
      gtin: num,
    };
  },

  transmuteSearchResultToDisplayInfo(
    result: ArtikleSearchResult,
    modals?: ModalController
  ): ArtikelSucheDisplayInfo {
    if (!result.artikel || !result.ruecknahmeInfo) {
      return {};
    }

    if (result.errorTyp !== MESSAGES.ERR_NO_ERROR) {
      if (modals)
        modals.showMessageDialog(
          this.getAbrechnungsinformationenErrorText(
            result.errorTyp,
            result.ruecknahmeInfo.gtin,
            result.ruecknahmeInfo.maschineId
          )
        );
      return {};
    }

    return {
      artikelLangText: result.artikel.artikelLangText,
      gueltigAb: result.artikel.gueltigAb,
      gueltigBis: result.artikel.gueltigBis,
      letzteRuecknahmeArtikelOfAutomat:
        result.ruecknahmeInfo.letzteRuecknahmeArtikelOfAutomat,
      letzteRuecknahmeAutomat: result.ruecknahmeInfo.letzteRuecknahmeAutomat,
      letzteRuecknhameArtikelAllg:
        result.ruecknahmeInfo.letzteRuecknhameArtikelAllg,
    };
  },
};

export default ArtikelSucheController;
