import { Button } from "@mui/material";
import { DialogController } from "global/hook/modal/interface/ModalController";
import useModalController from "global/hook/modal/use-modals";
import MESSAGES from "global/messages";
import UnplausibleRechnungenService from "service/sap-service/UnplausibleRechnungen.service";
import { RechnungItem } from "../controller/UnplausibleRechnungenController";
import UnplausibleRechnungAddDialogContent from "./UnplausibleRechnungAddDialogContent";

export interface UnplausibleRechnungenGridActionsProps {
  reloadTrigger: Function;
  resetTrigger: Function;
  dialogController: DialogController;
}

interface EventReferenzItems {
  regulierungsBeleg: string;
  items: Array<string>;
}

const UnplausibleRechnungenGridActions = (
  props: UnplausibleRechnungenGridActionsProps
) => {
  const modals = useModalController();

  var eventReferenzItems: EventReferenzItems = {
    regulierungsBeleg: "",
    items: [],
  };
  const onEventSelectionChange = (
    referenz: string,
    selection: Array<string>
  ) => {
    eventReferenzItems = {
      regulierungsBeleg: referenz,
      items: selection,
    };
    props.dialogController.disableOkAction(selection.length === 0);
  };

  const handleSave = async () => {
    const items: Array<RechnungItem> = eventReferenzItems.items.map((item) => {
      return {
        id: item,
      } as RechnungItem;
    });
    const records =
      (await UnplausibleRechnungenService.createEvent(items)) ?? [];
    props.reloadTrigger(records.length > 0 ? records[0].eventId : null);
  };

  const showCreateEventDialog = () => {
    props.dialogController.showDialog({
      message: "",
      title: "",
      content: (
        <UnplausibleRechnungAddDialogContent
          onSelectionChange={onEventSelectionChange}
        />
      ),
      okActionDisabled: true,
      onOkClick: handleSave,
      onAbortClick: () => {},
      abortCaption: MESSAGES.BUTTON_CANCEL,
      dialogClassName: "dialog__CreateUnplausibleRechnungenEvent",
    });
  };

  return (
    <>
      <Button variant="secondary" onClick={() => props.resetTrigger()}>
        {MESSAGES.BUTTON_RESET}
      </Button>
      <Button variant="contained" onClick={() => props.reloadTrigger()}>
        {MESSAGES.BUTTON_APPLY_FILTER}
      </Button>
      <Button variant="contained" onClick={showCreateEventDialog}>
        {MESSAGES.UNPLAUSIBLE_RECHNUNGEN_CREATE_EVENT_BUTTON}
      </Button>
    </>
  );
};

export default UnplausibleRechnungenGridActions;
