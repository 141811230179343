import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import RemoteSearchSelectBox from "global/components/UI/SelectBox/RemoteSearchSelectBox";
import TextValue from "global/components/UI/TextValue/TextValue";
import {
  RNS_GLN_LIST,
  useEnumerator,
} from "global/hook/enumerator/use-enumerator";
import useModalController from "global/hook/modal/use-modals";
import MESSAGES from "global/messages";
import DateUtils from "global/util/DateUtils";
import { useState } from "react";
import RnsGln from "service/data-service/rns-controller/interface/RnsGln";
import RnsSearchResult, {
  Gln,
} from "service/data-service/rns-controller/interface/RnsSearchResult";
import RnsService from "service/data-service/rns-controller/Rns.service";
import "./datenpflegerns.scss";

const DatenpflegeRns: React.FC = () => {
  const modals = useModalController();

  const [glnDerRns, setGlnDerRns] = useState<string>("");
  const [gln, setGln] = useState<Gln>({} as Gln);
  const [rnsInfoChanges, setRnsInfoChanges] = useState<RnsGln>({} as RnsGln);

  const [searchingRns, setSearchingRns] = useState<boolean>(false);
  const [savingRns, setSavingRns] = useState<boolean>(false);

  const rnsGlnFilteredEnumerator = useEnumerator(RNS_GLN_LIST, true);

  const handleSearchButton = () => {
    if (glnDerRns.length !== 13) {
      modals.showMessageDialog(MESSAGES.RNS_13_GLN_EXPECTED);
      return;
    }
    setSearchingRns(true);
    RnsService.searchRNS(
      { gln: glnDerRns, callSource: "DATENPFLEGERNS" },
      (rsnResult: RnsSearchResult) => {
        setSearchingRns(false);
        onRsnSearchResult(rsnResult);
      },
      (error: Error) => {
        setSearchingRns(false);
        switchGln({} as Gln);
      }
    );
  };

  function onRsnSearchResult(rnsResult: RnsSearchResult) {
    let data: Gln = {} as Gln;
    if (rnsResult.rows) {
      if (rnsResult.rows.length === 0) {
        modals.showMessageDialog(MESSAGES.RNS_NO_RUCKNAHMESTELLE_FOR_GLN);
      } else if (rnsResult.rows.length > 1) {
        modals.showMessageDialog(MESSAGES.RNS_MULTIPLE_RUCKNAHMESTELLE_FOR_GLN);
      } else {
        data = {
          ...rnsResult.rows[0],
        };
      }
    }
    switchGln(data);
  }

  function switchGln(refGln: Gln) {
    setGln({
      ...refGln,
      sapGueltigBis: refGln.sapGueltigBis,
      sapGueltigVon: refGln.sapGueltigVon,
    });
    setRnsInfoChanges(refGln);
  }

  const handleSaveButton = () => {
    if (checkRnsHasChanges()) {
      setSavingRns(true);
      RnsService.updateContactOfGLN(
        rnsInfoChanges,
        (data: any) => {
          setSavingRns(false);
          switchGln({ ...data });
          modals.showMessageDialog(MESSAGES.RNS_SAVE_CONFIRMATION);
        },
        (error: Error) => {
          setSavingRns(false);
        }
      );
    } else {
      modals.showMessageDialog(MESSAGES.DIALOG_CONTENT_NO_CHANGES);
    }
  };

  function checkRnsHasChanges(): boolean {
    return (
      gln.kontaktName !== rnsInfoChanges.kontaktName ||
      gln.kontaktTelefon1 !== rnsInfoChanges.kontaktTelefon1 ||
      gln.kontaktTelefon2 !== rnsInfoChanges.kontaktTelefon2 ||
      gln.kontaktEmail !== rnsInfoChanges.kontaktEmail ||
      gln.bemerkung1 !== rnsInfoChanges.bemerkung1 ||
      gln.bemerkung2 !== rnsInfoChanges.bemerkung2 ||
      gln.bemerkung3 !== rnsInfoChanges.bemerkung3
    );
  }

  function trackRnsInfoChange(data: RnsGln) {
    setRnsInfoChanges((prevRnsInfo) => ({ ...prevRnsInfo, ...data }));
  }

  return (
    <Stack className="datenpflegeRnsStack" columnGap={2}>
      <Grid container>
        <Grid container item>
          <Grid className="grid_item_30rem" item xs={12} sm={6}>
            <RemoteSearchSelectBox
              label="GLN der RNS :"
              enumerator={rnsGlnFilteredEnumerator}
              id="gln-der-rns-input"
              getSelectedValue={(newValue) => {
                setGlnDerRns(newValue ?? "");
              }}
            />
            <Box className="rnsButtonBox">
              <Button variant="contained" onClick={handleSearchButton}>
                RNS Suchen
              </Button>
              {searchingRns && (
                <CircularProgress
                  className="rnsButtonActionProgress"
                  size={24}
                />
              )}
              {searchingRns && (
                <Typography className="uiElement__TextElement">
                  Rücknahmestelle wird gesucht...
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>

        <Grid className="grid_item_30rem" item xs={12} sm={6}>
          <Box className="rnsTextBox">
            <TextValue label="GLN:" id="gln" value={gln.gln ?? ""} readOnly />
          </Box>
          <Box className="rnsTextBox">
            <TextValue
              label="Name:"
              value={gln.name ?? ""}
              id="name"
              readOnly
            />
          </Box>
          <Box className="rnsTextBox">
            <TextValue
              label="Straße:"
              value={gln.strasse ?? ""}
              id="strasse"
              readOnly
            />
          </Box>
          <Box className="rnsTextBox">
            <TextValue label="PLZ:" value={gln.plz ?? ""} id="plz" readOnly />
          </Box>
          <Box className="rnsTextBox">
            <TextValue label="Ort:" value={gln.ort ?? ""} id="ort" readOnly />
          </Box>
          <Box className="rnsTextBox">
            <TextValue
              label="Datum von:"
              value={DateUtils.optionalTimeArrayToGermanString(
                gln.sapGueltigVon
              )}
              id="sap-gueltig-von"
              readOnly
            />
          </Box>
          <Box className="rnsTextBox">
            <TextValue
              label="Datum bis:"
              value={DateUtils.optionalTimeArrayToGermanString(
                gln.sapGueltigBis
              )}
              id="sap-gueltig-bis"
              readOnly
            />
          </Box>
        </Grid>
        <Grid className="grid_item_40rem" item xs={12} sm={6}>
          <TextValue
            label="Ansprechpartner:"
            value={rnsInfoChanges.kontaktName ?? ""}
            id="partner-ansprechpartner"
            onChange={(event) =>
              trackRnsInfoChange({ kontaktName: event.target.value } as RnsGln)
            }
          />
          <TextValue
            label="Telefon1:"
            value={rnsInfoChanges.kontaktTelefon1 ?? ""}
            id="partner-telefon1"
            onChange={(event) =>
              trackRnsInfoChange({
                kontaktTelefon1: event.target.value,
              } as RnsGln)
            }
          />
          <TextValue
            label="Telefon2:"
            value={rnsInfoChanges.kontaktTelefon2 ?? ""}
            id="partner-telefon2"
            onChange={(event) =>
              trackRnsInfoChange({
                kontaktTelefon2: event.target.value,
              } as RnsGln)
            }
          />
          <TextValue
            label="EMail:"
            value={rnsInfoChanges.kontaktEmail ?? ""}
            id="partner-email"
            onChange={(event) =>
              trackRnsInfoChange({ kontaktEmail: event.target.value } as RnsGln)
            }
          />
          <TextValue
            label="Bemerkung1:"
            value={rnsInfoChanges.bemerkung1 ?? ""}
            id="partner-bemerkung1"
            onChange={(event) =>
              trackRnsInfoChange({ bemerkung1: event.target.value } as RnsGln)
            }
          />
          <TextValue
            label="Bemerkung2:"
            value={rnsInfoChanges.bemerkung2 ?? ""}
            id="partner-bemerkung2"
            onChange={(event) =>
              trackRnsInfoChange({ bemerkung2: event.target.value } as RnsGln)
            }
          />
          <TextValue
            label="Bemerkung3:"
            value={rnsInfoChanges.bemerkung3 ?? ""}
            id="partner-bemerkung3"
            onChange={(event) =>
              trackRnsInfoChange({ bemerkung3: event.target.value } as RnsGln)
            }
          />
          <Box className="rnsTextBox">
            <TextValue
              label="Organisationseinheit:"
              value={gln.edekaOrganisation?.name ?? ""}
              id="partner-organisation"
              multiline
              readOnly
            />
          </Box>
          <Box className="rnsButtonBox">
            <Button
              variant="contained"
              onClick={handleSaveButton}
              disabled={savingRns}
            >
              {MESSAGES.BUTTON_RNS_SAVE}
            </Button>
            {savingRns && (
              <CircularProgress className="rnsButtonActionProgress" size={24} />
            )}
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default DatenpflegeRns;
