import { Button } from "@mui/material";
import useModalController from "global/hook/modal/use-modals";
import MESSAGES from "global/messages";
import Anwendernachricht from "service/data-service/anwender-controller/interface/Anwendernachricht";
import AnwendernachrichtenUtil from "./AnwendernachrichtenUtil";

export interface AnwendernachrichtenGridActionsProps {
  record?: Anwendernachricht;
  reloadTrigger: Function;
  resetTrigger: Function;
}
const MAX_NACHRICHT_LENGTH = 240;

const AnwendernachrichtenGridActions = (
  props: AnwendernachrichtenGridActionsProps
) => {
  const modals = useModalController();

  const handleSave = () => {
    if (props.record!.nachricht!.length > MAX_NACHRICHT_LENGTH) {
      modals.showMessageDialog(
        MESSAGES.ANWENDER_MAX_LENGTH(MAX_NACHRICHT_LENGTH)
      );
    } else {
      AnwendernachrichtenUtil.update(
        props.record!,
        props.reloadTrigger,
        modals
      );
    }
  };

  return (
    <>
      <Button
        size="small"
        variant="contained"
        disabled={!props.record?.key}
        onClick={() => {
          AnwendernachrichtenUtil.delete(
            props.record!,
            props.reloadTrigger,
            modals
          );
        }}
      >
        {MESSAGES.BUTTON_DELETE}
      </Button>
      <Button
        size="small"
        variant="secondary"
        onClick={() => props.resetTrigger()}
      >
        {MESSAGES.BUTTON_RESET}
      </Button>
      <Button
        size="small"
        variant="contained"
        disabled={
          !(
            props.record?.nachricht &&
            props.record.anzeigeDatumVon &&
            props.record.anzeigeZeitVon &&
            props.record.anzeigeDatumBis &&
            props.record.anzeigeZeitBis
          )
        }
        onClick={handleSave}
      >
        {MESSAGES.BUTTON_SAVE}
      </Button>
    </>
  );
};

export default AnwendernachrichtenGridActions;
